var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dados-seguradora" },
    [
      _c("div", { staticClass: "vx-row px-8 mb-5" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "vx-col w-full mt-5" },
          [
            _c(
              "multiselect",
              {
                staticClass: "select-options w-full",
                attrs: {
                  id: "buscar-segurado",
                  label: "Name",
                  placeholder:
                    "Digite o CNPJ ou Razão Social para buscar um Segurado",
                  "open-direction": "bottom",
                  options: _vm.dadosBuscaSegurado,
                  searchable: true,
                  loading: _vm.isLoading,
                  "internal-search": false,
                  "clear-on-select": false,
                  "options-limit": 300,
                  limit: 3,
                  "max-height": 600,
                  "show-no-options": false,
                  "show-no-results": true,
                  "hide-selected": true,
                  "select-label": "",
                },
                on: { "search-change": _vm.buscarSegurado },
                scopedSlots: _vm._u([
                  {
                    key: "singleLabel",
                    fn: function (props) {
                      return [
                        _c("span", { staticClass: "option__desc" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "option__title text-sm truncate w-full inline-block md:text-base lg:text-x1",
                            },
                            [_vm._v(_vm._s(props.option.Name))]
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "option",
                    fn: function (props) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "option__desc",
                            on: {
                              click: function ($event) {
                                return _vm.validarSegurado(
                                  props.option.DocumentNumber,
                                  _vm.cnpjTomador
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "option__title" }, [
                              _c("b", [_vm._v(_vm._s(props.option.Name))]),
                            ]),
                            props.option.DocumentNumber
                              ? _c(
                                  "p",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("the-mask", {
                                      staticClass: "w-full the-mask",
                                      attrs: {
                                        mask: [
                                          props.option.DocumentIsCPF
                                            ? "###.###.###-##"
                                            : "##.###.###/####-##",
                                        ],
                                        disabled: "true",
                                        masked: true,
                                        value: props.option.DocumentNumber,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            props.option.Description
                              ? _c("p", { staticClass: "mt-3" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(props.option.Description) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "clear",
                    fn: function (props) {
                      return [
                        _c("div", {
                          staticClass: "multiselect__clear",
                          on: {
                            mousedown: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.clearAll(props.search)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.cnpjRazaoSocial,
                  callback: function ($$v) {
                    _vm.cnpjRazaoSocial = $$v
                  },
                  expression: "cnpjRazaoSocial",
                },
              },
              [
                _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                  _vm._v("Não foi encontrado nenhum segurado na base."),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      !!_vm.dadosSegurado.CpfCnpj
        ? _c("div", [
            _c("div", { staticClass: "vx-row px-8 mb-5" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/2 w-full mt-5" },
                [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.dadosSegurado.Entity == "F"
                            ? "Nome Completo"
                            : "Razão Social"
                        ) +
                        "\n        "
                    ),
                  ]),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { disabled: "", size: "large" },
                    model: {
                      value: _vm.dadosSegurado.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.dadosSegurado, "Name", $$v)
                      },
                      expression: "dadosSegurado.Name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/2 w-full mt-5" },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.dadosSegurado.Entity == "F" ? "CPF" : "CNPJ")
                    ),
                  ]),
                  _c("the-mask", {
                    staticClass: "w-full input-the-mask",
                    attrs: {
                      mask: [
                        _vm.dadosSegurado.Entity == "F"
                          ? "###.###.###-##"
                          : "##.###.###/####-##",
                      ],
                      disabled: "true",
                      masked: true,
                      value: _vm.dadosSegurado.CpfCnpj,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row px-8 mb-5" }, [
              _vm.dadosSegurado.Entity === "J"
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mt-5" },
                    [
                      _c("p", [
                        _vm._v(
                          "\n          TÍTULO DO ESTABELECIMENTO  (Nome Fantasia)\n        "
                        ),
                      ]),
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { disabled: "", size: "large" },
                        model: {
                          value: _vm.dadosSegurado.NomeSocial,
                          callback: function ($$v) {
                            _vm.$set(_vm.dadosSegurado, "NomeSocial", $$v)
                          },
                          expression: "dadosSegurado.NomeSocial",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.dadosSegurado.Entity === "F" &&
                  _vm.podeHabilitarCadastroNomeSocial &&
                  _vm.dadosSegurado.NomeSocial
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mt-5" },
                    [
                      _c("p", [_vm._v("\n          Nome Social\n        ")]),
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { disabled: "", size: "large" },
                        model: {
                          value: _vm.dadosSegurado.NomeSocial,
                          callback: function ($$v) {
                            _vm.$set(_vm.dadosSegurado, "NomeSocial", $$v)
                          },
                          expression: "dadosSegurado.NomeSocial",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dadosSegurado.Entity === "J"
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mt-5" },
                    [
                      _vm.dadosSegurado
                        ? _c("FantasySegurado", {
                            attrs: { segurado: _vm.dadosSegurado },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.dadosSegurado.Entity === "F" &&
                  _vm.podeHabilitarCadastroNomeSocial
                ? _c(
                    "div",
                    { staticClass: "vx-col w-full mt-5" },
                    [
                      _vm.dadosSegurado
                        ? _c("FantasySegurado", {
                            attrs: { segurado: _vm.dadosSegurado },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "vx-row px-8 mb-5" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-full" },
                [
                  _c("p", [_vm._v("Endereço Principal")]),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      disabled: "",
                      value: _vm.enderecoPrincipal.Address,
                      size: "large",
                    },
                  }),
                ],
                1
              ),
            ]),
            (_vm.listaEnderecos || []).length > 0
              ? _c(
                  "div",
                  { staticClass: "m-8" },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "mb-8 text-base md:text-lg lg:text-2x1 xl:text-3x1",
                      },
                      [
                        _vm._v(
                          "\n        Caso deseje utilizar outro endereço para o Segurado, selecione abaixo\n        ou adicione um.\n      "
                        ),
                      ]
                    ),
                    _vm._l(_vm.listaEnderecos, function (endereco, index) {
                      return _c("vs-card", { key: endereco.Id }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center p-5" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "w-full",
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleEndereco(endereco)
                                  },
                                },
                                model: {
                                  value: _vm.enderecoSelecionado[endereco.Id],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.enderecoSelecionado,
                                      endereco.Id,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "enderecoSelecionado[endereco.Id]",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-2 mr-3 flex flex-col flex-grow",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-base font-semibold",
                                      },
                                      [_vm._v(_vm._s(endereco.Address))]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("vs-button", {
                              attrs: {
                                radius: "",
                                color: "primary",
                                type: "flat",
                                size: "large",
                                icon: "delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeEndereco(endereco.Id, index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "p-8 flex flex-row-reverse" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: {
                      type: "flat",
                      icon: "add_circle",
                      "icon-after": true,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.incluirNovoEnderecoSegurado()
                      },
                    },
                  },
                  [_vm._v("\n        Incluir Endereço adicional")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticClass: "endereco",
          attrs: {
            title: "",
            buttonCloseHidden: _vm.addressError,
            active: _vm.popupAddEndereco,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupAddEndereco = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "pl-4 pb-6" }, [_vm._v("Novo Endereço")]),
          _vm.addressError
            ? _c(
                "vs-alert",
                {
                  staticClass: "pl-4 h-auto mt-2 mb-3",
                  attrs: { color: "warning" },
                },
                [
                  _vm._v(
                    "\n      Não encontramos nenhum endereço vinculado. Por favor, adicione para\n      continuar.\n    "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "p-4 pt-0" },
            [
              _c(
                "div",
                { staticClass: "vx-col" },
                [
                  _c("ValidationProvider", {
                    attrs: { name: "CEP", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "#####-###",
                                  expression: "'#####-###'",
                                },
                                {
                                  name: "debounce",
                                  rawName: "v-debounce:1s",
                                  value: _vm.buscarCep,
                                  expression: "buscarCep",
                                  arg: "1s",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: { label: "CEP", size: "large" },
                              model: {
                                value: _vm.cep,
                                callback: function ($$v) {
                                  _vm.cep = $$v
                                },
                                expression: "cep",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: errors[0],
                                    expression: "errors[0]",
                                  },
                                ],
                                staticClass: "text-danger",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(errors[0]) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.novoEndereco.ZipCode
                ? [
                    _c(
                      "div",
                      { staticClass: "mt-5 flex flex-wrap justify-around" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col pr-1 w-3/4 md:pr-4" },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "Logradouro", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: "Logradouro",
                                            size: "large",
                                          },
                                          model: {
                                            value: _vm.novoEndereco.AddressName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.novoEndereco,
                                                "AddressName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "novoEndereco.AddressName",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]",
                                              },
                                            ],
                                            staticClass: "text-danger",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3515018384
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-1/4" },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "Número", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: "Número",
                                            size: "large",
                                          },
                                          model: {
                                            value: _vm.novoEndereco.Number,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.novoEndereco,
                                                "Number",
                                                $$v
                                              )
                                            },
                                            expression: "novoEndereco.Number",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]",
                                              },
                                            ],
                                            staticClass: "text-danger",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3842726071
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-5 flex flex-wrap justify-around" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "vx-col pr-1 w-full md:w-3/5 md:pr-4",
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { label: "Complemento", size: "large" },
                              model: {
                                value: _vm.novoEndereco.Complement,
                                callback: function ($$v) {
                                  _vm.$set(_vm.novoEndereco, "Complement", $$v)
                                },
                                expression: "novoEndereco.Complement",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col mt-5 w-full md:w-2/5 md:mt-0",
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "Bairro", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: "Bairro",
                                            size: "large",
                                          },
                                          model: {
                                            value:
                                              _vm.novoEndereco.Neighborhood,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.novoEndereco,
                                                "Neighborhood",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "novoEndereco.Neighborhood",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]",
                                              },
                                            ],
                                            staticClass: "text-danger",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2346390766
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-5 flex flex-wrap justify-around md:w-3/5",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "vx-col pr-1 w-full md:w-1/2 md:pr-4",
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "Cidade", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: "Cidade",
                                            size: "large",
                                          },
                                          model: {
                                            value: _vm.novoEndereco.CityName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.novoEndereco,
                                                "CityName",
                                                $$v
                                              )
                                            },
                                            expression: "novoEndereco.CityName",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]",
                                              },
                                            ],
                                            staticClass: "text-danger",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3032145211
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vx-col mt-5 w-full md:w-1/2 md:mt-0",
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "Estado", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            label: "Estado",
                                            size: "large",
                                          },
                                          model: {
                                            value:
                                              _vm.novoEndereco
                                                .StateProvinceName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.novoEndereco,
                                                "StateProvinceName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "novoEndereco.StateProvinceName",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]",
                                              },
                                            ],
                                            staticClass: "text-danger",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(errors[0]) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                92504215
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "p-4 mt-5 flex flex-row-reverse w-full" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { disabled: !_vm.podeAdicionarEndereco },
                            on: {
                              click: function ($event) {
                                return _vm.salvarendereco(_vm.novoEndereco)
                              },
                            },
                          },
                          [_vm._v("Salvar Endereço")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h4",
      { staticClass: "vx-col w-full flex items-center font-semibold" },
      [
        _c("span", { staticClass: "block" }, [
          _vm._v(
            "Digite o CPF/CNPJ ou Nome/Razão Social para buscar um Segurado."
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }