<template>
  <section class="required-documents">
    <vs-popup
      class="popup-list-documents"
      title="Solicitação de Revisão de limite"
      :active.sync="showModalDocuments"
    >
      <el-alert
      class="mb-8 px-8"
        v-if="success"
        title="Documento enviados com sucesso!"
        type="success"
        description="Sua solicitação foi enviada para análise da seguradora!"
        show-icon
      >
      </el-alert>
      <div class="mb-8 px-8" v-else>
        <p class="mb-5">Documentos marcados com * são obrigatórios</p>

        <vs-list>
          <vs-list-item
            :class="doc.StatusName"
            v-for="(doc, index) in detalhamento"
            :key="index"
            :title="doc.Name + (doc.IsRequired ? ' *' : '')"
            :subtitle="doc.StatusDescription"
          >
            <vs-button
              v-if="doc.StatusName !== 'Conforme'"
              size="large"
              type="flat"
              icon="cloud_upload"
              @click="modalUploadOpen(doc, index)"
            />
          </vs-list-item>
        </vs-list>

        <div class="mt-4">
          <p class="mb-2">Comentário</p>
          <vs-textarea v-model="observation" />
        </div>
        <div class="text-right mt-5 w-full">
          <vs-button
            color="primary"
            :disabled="validaDocumentosObrigatorios"
            type="filled"
            @click="finalizarPendencia()"
            >Submeter para seguradora</vs-button
          >
        </div>
      </div>
    </vs-popup>
    <multiUpload
      v-if="modalUpload"
      :modalUpload="modalUpload"
      :documentoName="(selectedDocument || {}).Name"
      @callBackUpload="callBackUpload"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import multiUpload from "@/components/upload-multiple/index.vue";
export default {
  name: "request-limit",
  components: {
    multiUpload
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    detalhamento: {
      type: Array,
      required: true
    },
    // propostaUniqueId: {
    //   type: String,
    //   required: true
    // },
    tomadorUniqueId: {
      type: String
    }
  },
  data() {
    return {
      modalUpload: false,
      uploadDocumentController: {},
      selectedDocument: {},
      next: false,
      observation: "",
      success: false
    };
  },
  computed: {
    showModalDocuments: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit("dismissModal", value);
      }
    },
    validaDocumentosObrigatorios() {
      let disabled = false;
      this.detalhamento.forEach(doc => {
        if (doc.IsRequired && doc.StatusName != "Conforme") {
          disabled = true;
        }
      });
      return disabled;
    }
  },
  methods: {
    ...mapActions("tomador-module", [
      "uploadDocumentoByRequestLimit",
      "sendRequestToAnalysis"
    ]),

    modalUploadOpen(document, index) {
      this.selectedDocument = document;
      this.selectedDocument.index = index;
      this.modalUpload = true;
    },

    async finalizarPendencia() {
      if (this.observation.length === 0) {
        this.$onpoint.errorModal("Preencha o campo de comentário.");
        return;
      }
      await this.$onpoint.loadingModal(
        "Estamos processando sua solicitação",
        async () => {
          return await this.sendRequestToAnalysis({
            PolicyHolderUniqueId: this.tomadorUniqueId,
            Observation: this.observation,
            Files: this.detalhamento,
            brokerId: this.$store.state["auth"].userInfo.BrokerId,
          })
            .then(() => {
              this.$emit("closeModal");
              this.popupDetalhamento = false;
              this.$onpoint.successModal(
                "Solicitação de revisão de limite recebida! Brevemente iniciaremos a análise, fique atento, pois poderemos solicitar mais informações via e-mail. Após a análise você receberá e-mail com o resultado de sua solicitação. "
              );
              this.success = true;
            })
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        }
      );
    },

    async uploadDocument(request) {},

    closeModal() {
      this.modalUpload = false;
    },
    async callBackUpload(callback) {
      this.modalUpload = false;
      const request = {
        RequestDocumentName: this.selectedDocument.Name,
        PolicyHolderUniqueId: this.tomadorUniqueId,
        Files: callback
      };
      return await this.uploadDocumentoByRequestLimit(request)
        .then(response => {
          this.$onpoint.successModal("Documento enviado com sucesso.");
          this.detalhamento[this.selectedDocument.index].StatusDescription =
            "Enviado";
          this.detalhamento[this.selectedDocument.index].StatusName =
            "Conforme";
          this.selectedDocument = {};
          this.$vs.loading.close();
        })
        .catch(error => {
          this.$onpoint.errorModal(error.response.data.Errors);
          this.$vs.loading.close();
        });
    }
  }
};
</script>

<style lang="scss">
.error-modal,
.success-modal,
.onpoint-loadingmodal {
  z-index: 53003;
}

.popup-list-documents {
  z-index: 52850 !important;

  .vs-popup {
    width: 717px;

    .vs-popup--content {
      padding-top: 0;
      margin-top: 0;
    }
  }

  .line-vs-tabs {
    background: rgba(var(--vs-warning), 1) !important;
  }

  .vs-tabs--content {
    padding: 20px 0 5px 0;

    .card-follow-up {
      background: #ffffff;
      box-shadow: 0 0;
      border: 1px solid #e4e1e1;
      box-sizing: border-box;
      padding: 16px;
      border-radius: 0;

      .vs-card--content {
        margin: 0;

        .title-card {
          font-size: 16px;

          p {
            color: #8e8e8e;
            font-size: 14px;
          }
        }
      }
    }
  }

  .vs-list,
  .vs-list .vs-list--item {
    padding: 0 !important;
  }

  .vs-list--item {
    border-bottom: none;

    &.Conforme {
      .vs-list--subtitle {
        border: solid 1px #00c77a;
        color: #00c77a;
      }
    }

    &.Pendente {
      .vs-list--subtitle {
        border: solid 1px #ff9100;
        color: #ff9100;
      }
    }

    &.Aguardando_Analise {
      .vs-list--subtitle {
        border: solid 1px #0096d1;
        color: #0096d1;
      }
    }

    &.Reprovado {
      .vs-list--subtitle {
        border: solid 1px #c40f06;
        color: #c40f06;
      }
    }
  }

  .list-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .vs-list--subtitle {
      color: white;
      padding: 2px 5px;
      border-radius: 10px;
    }
  }

  .obs {
    width: 80%;
  }

  .badge {
    position: absolute;
    color: #0f6eb4;
    display: grid;
    place-items: center;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin-left: 250px;
    margin-top: 5px;

    span {
      font-size: 16px;
    }
  }

  .mensagem {
    border: solid 1px rgba(0, 157, 223, 0.2);
  }
}

.popup-upload-documento {
  z-index: 52851 !important;
}
</style>
