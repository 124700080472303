var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "fantasy-name" },
    [
      (_vm.seguradoFantasyName.list || []).length > 0
        ? _c(
            "div",
            { staticClass: "m-8" },
            [
              _c(
                "h3",
                {
                  staticClass:
                    "mb-8 text-base md:text-lg lg:text-2x1 xl:text-3x1",
                },
                [
                  _vm._v(
                    "\n      Caso deseje utilizar outro " +
                      _vm._s(
                        _vm.segurado.Entity === "J"
                          ? "Nome Fantasia"
                          : "Nome Social"
                      ) +
                      "  para o Segurado, selecione\n      abaixo ou adicione um.\n    "
                  ),
                ]
              ),
              (_vm.seguradoFantasyName.list || []).length <= 2
                ? _c(
                    "div",
                    {},
                    _vm._l(
                      _vm.seguradoFantasyName.list,
                      function (item, index) {
                        return _c("vs-card", { key: item.Id }, [
                          _c(
                            "div",
                            { staticClass: "flex items-center p-5" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "fantasy-radio w-full flex flex-col flex-grow",
                                },
                                [
                                  _c("label", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.fantasyNameSelecionado,
                                          expression: "fantasyNameSelecionado",
                                        },
                                      ],
                                      attrs: { type: "radio", name: "radio" },
                                      domProps: {
                                        value: item.Id,
                                        checked: _vm._q(
                                          _vm.fantasyNameSelecionado,
                                          item.Id
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleFantasyName(item)
                                        },
                                        change: function ($event) {
                                          _vm.fantasyNameSelecionado = item.Id
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "text-base font-semibold",
                                      },
                                      [_vm._v(_vm._s(item.Name))]
                                    ),
                                  ]),
                                ]
                              ),
                              item.IsMainName != true
                                ? _c("vs-button", {
                                    attrs: {
                                      radius: "",
                                      color: "primary",
                                      type: "flat",
                                      size: "large",
                                      icon: "delete",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFantasyName(
                                          item,
                                          index
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      }
                    ),
                    1
                  )
                : _c(
                    "vs-collapse",
                    {
                      staticClass: "custom-tab-fantasy",
                      attrs: { type: "border" },
                    },
                    [
                      _c(
                        "vs-collapse-item",
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.seguradoFantasyName.list.length || []
                                  ) +
                                  " Nomes Fantasia\n          encontrados.\n        "
                              ),
                            ]
                          ),
                          _vm._l(
                            _vm.seguradoFantasyName.list,
                            function (item, index) {
                              return _c("vs-card", { key: item.Id }, [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center p-5" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "fantasy-radio w-full flex flex-col flex-grow",
                                      },
                                      [
                                        _c("label", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.fantasyNameSelecionado,
                                                expression:
                                                  "fantasyNameSelecionado",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              name: "radio",
                                            },
                                            domProps: {
                                              value: item.Id,
                                              checked: _vm._q(
                                                _vm.fantasyNameSelecionado,
                                                item.Id
                                              ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleFantasyName(
                                                  item
                                                )
                                              },
                                              change: function ($event) {
                                                _vm.fantasyNameSelecionado =
                                                  item.Id
                                              },
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-base font-semibold",
                                            },
                                            [_vm._v(_vm._s(item.Name))]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    item.IsMainName != true
                                      ? _c("vs-button", {
                                          attrs: {
                                            radius: "",
                                            color: "primary",
                                            type: "flat",
                                            size: "large",
                                            icon: "delete",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeFantasyName(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse" },
        [
          _c(
            "vs-button",
            {
              attrs: { type: "flat", icon: "add_circle", "icon-after": true },
              on: {
                click: function ($event) {
                  return _vm.includeNewFantasyName()
                },
              },
            },
            [
              _vm._v(
                "\n      Incluir  " +
                  _vm._s(
                    _vm.segurado.Entity === "J"
                      ? "Nome Fantasia"
                      : "Nome Social"
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "nomefantasia",
          attrs: { title: "", active: _vm.popupNomeFantasia },
          on: {
            "update:active": function ($event) {
              _vm.popupNomeFantasia = $event
            },
          },
        },
        [
          _vm.segurado.Entity === "J"
            ? _c("h3", { staticClass: "pl-4 pb-6" }, [_vm._v("Nome Fantasia")])
            : _c("h3", { staticClass: "pl-4 pb-6" }, [_vm._v("Nome Social")]),
          _c("div", { staticClass: "p-4 pt-0" }, [
            _vm.segurado.Entity === "J"
              ? _c(
                  "div",
                  { staticClass: "vx-col" },
                  [
                    _c("ValidationProvider", {
                      ref: "fantasyNameRegex",
                      attrs: { name: "Nome Fantasia", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("vs-input", {
                                  staticClass: "w-full",
                                  attrs: {
                                    label: "Novo nome fantasia",
                                    size: "large",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.validatedFantasyName(
                                        _vm.novoNomeFantasia
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.novoNomeFantasia,
                                    callback: function ($$v) {
                                      _vm.novoNomeFantasia = $$v
                                    },
                                    expression: "novoNomeFantasia",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: errors[0],
                                        expression: "errors[0]",
                                      },
                                    ],
                                    staticClass: "text-danger",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(errors[0]) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-4 mt-5 flex flex-row-reverse w-full",
                                  },
                                  [
                                    _c(
                                      "vs-button",
                                      {
                                        attrs: {
                                          disabled:
                                            errors[0] ||
                                            !_vm.validatedFantasyName(
                                              _vm.novoNomeFantasia
                                            ) ||
                                            _vm.novoNomeFantasia === "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveFantasyName()
                                          },
                                        },
                                      },
                                      [_vm._v("Salvar Nome Fantasia")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1674597056
                      ),
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "vx-col" },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "Nome Social", rules: "required" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ errors }) {
                            return [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  label: "Novo nome Social",
                                  size: "large",
                                },
                                model: {
                                  value: _vm.novoNomeFantasia,
                                  callback: function ($$v) {
                                    _vm.novoNomeFantasia = $$v
                                  },
                                  expression: "novoNomeFantasia",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors[0],
                                      expression: "errors[0]",
                                    },
                                  ],
                                  staticClass: "text-danger",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(errors[0]) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-4 mt-5 flex flex-row-reverse w-full",
                                },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      attrs: { disabled: errors[0] },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveFantasyName()
                                        },
                                      },
                                    },
                                    [_vm._v("Salvar Nome Social")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }