<template>
  <div class="con-upload">
    <vs-popup
      title=""
      class="popup-upload-documento"
      :active.sync="modalUpload"
      button-close-hidden
    >
      <div class="">
        <h4 class="text-center" v-if="!insertName">
          Upload de Documento - {{ documentoName }}
        </h4>
        <h4 class="text-center" v-else>Upload de Documento</h4>
      </div>
      <az-view-upload v-if="viewActive" :src="viewSrc" />
      <div class="con-img-upload">
        <div
          v-for="(file, index) in files"
          :class="{
            fileError: file.error
          }"
          :key="index"
          class="img-upload"
        >
          <button
            class="btn-x-file"
            type="button"
            @click="removeFile(file, index)"
          >
            <i translate="no" class="material-icons notranslate">
              clear
            </i>
          </button>
          <button
            v-if="showUploadButton"
            :class="{
              'on-progress': file.getProgress(),
              'ready-progress': file.getProgress() >= 100
            }"
            :style="{
              height: `${
                file.getProgress() > 0 ? file.getProgress() : undefined
              }%`
            }"
            class="btn-upload-file"
          >
            <i translate="no" class="material-icons notranslate">
              {{
                file.getProgress() >= 100
                  ? file.error
                    ? "report_problem"
                    : "cloud_done"
                  : ""
              }}
            </i>
            <span>{{ file.getProgress() }} %</span>
          </button>
          <img
            v-if="file.src"
            :style="{
              maxWidth: file.orientation == 'h' ? '100%' : 'none',
              maxHeight: file.orientation == 'w' ? '100%' : 'none'
            }"
            :key="index"
            :src="file.src"
            @touchend="viewImage(file.src, $event)"
            @click="viewImage(file.src, $event)"
          />
          <h4 v-if="!file.src" class="text-archive">
            <i translate="no" class="material-icons notranslate">
              description
            </i>
            <span>
              {{ file.name }}
            </span>
          </h4>
        </div>
        <div
          :class="{
            'on-progress-all-upload': percent != 0,
            'is-ready-all-upload': percent >= 100,
            'disabled-upload':
              $attrs.hasOwnProperty('disabled') || limit
                ? srcs.length - itemRemove.length >= Number(limit)
                : false
          }"
          v-if="
            (singleUpload && (files || []).length < 1) || singleUpload == false
          "
          class="con-input-upload"
        >
          <input
            ref="fileInput"
            v-bind="$attrs"
            :disabled="
              $attrs.disabled || limit
                ? srcs.length - itemRemove.length >= Number(limit)
                : false
            "
            type="file"
            @change="getFiles"
          />
          <span class="text-input">
            {{ text }}
          </span>
          <span
            :style="{
              width: `${percent}%`
            }"
            class="input-progress"
          >
          </span>
          <button
            v-if="showUploadButton"
            :disabled="filesx.length == 0"
            type="button"
            title="Upload"
            class="btn-upload-all vs-upload--button-upload"
            @click="upload('all')"
          >
            <i translate="no" class="material-icons notranslate">
              cloud_upload
            </i>
          </button>
        </div>
      </div>
      <el-alert
        title="Somente arquivos do tipo: png, jpg, xlsx, docx ou pdf, com no máximo 20mb."
        type="warning"
      >
      </el-alert>
      <br />
      <div class="vx-row" v-if="insertName">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Nomear documento:</p>
          <vs-input class="w-full" v-model="documentoText" size="large" />
        </div>
      </div>
      <div class="text-right mt-5 w-full">
        <vs-button
          @click.native="close()"
          color="danger"
          style="margin-right: 10px"
        >
          <span class="font-semibold pr-2 pl-2"> Cancelar </span>
        </vs-button>
        <vs-button @click.native="upload()" color="primary" v-if="base64">
          <span class="font-semibold pr-2 pl-2"> Enviar documentos </span>
        </vs-button>
        <vs-button disabled color="primary" v-else>
          <span class="font-semibold pr-2 pl-2"> Enviar documentos </span>
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import viewUpload from "./dependencies/view-upload";
import { mapGetters } from "vuex";
import instance from "@/axios";
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });
};
let lastTap = 0;
let $controller = undefined;
export default {
  name: "upload-multiple",
  components: {
    viewUpload
  },
  inheritAttrs: false,
  props: {
    documentoName: {
      default: null,
      type: String
    },
    modalUpload: {
      default: null,
      type: Boolean
    },
    text: {
      default: "Clique aqui para selecionar o arquivo.",
      type: String
    },
    limit: {
      default: null,
      type: [Number, String]
    },
    data: {
      default: null,
      type: Object
    },
    showUploadButton: {
      default: true,
      type: Boolean
    },
    singleUpload: {
      default: false,
      type: Boolean
    },
    insertName: {
      default: false,
      type: Boolean
    },
    controller: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    documentoText: "",
    inputValue: null,
    type: null,
    srcs: [],
    filesx: [],
    files: [],
    itemRemove: [],
    percent: 0,
    viewActive: false,
    viewSrc: null,
    base64: [],
    Filename: false,
    FileExtension: false
  }),
  computed: {
    ...mapGetters("auth", ["storageInfo"]),

    getFilesFilter() {
      return this.filesx;
      const files = this.srcs.filter(item => {
        return !item.remove;
      });
      return files;
    },
    postFiles() {
      let postFiles = Array.prototype.slice.call(this.filesx);
      postFiles = postFiles.filter(item => {
        return !item.hasOwnProperty("remove");
      });
      return postFiles.length;
    }
  },
  watch: {
    percent() {
      if (this.percent >= 100) {
        this.srcs.forEach(file => {
          file.percent = 100;
        });
        setTimeout(() => {
          this.percent = 0;
        }, 1000);
      }
    }
  },
  methods: {
    veriryFile(name) {
      let toLowerCase = name.toLowerCase();
      let extension = toLowerCase.split(".").pop();
      let types = [
        "png",
        "jpeg",
        "jpg",
        "xlsx",
        "xls",
        "rtf",
        "docx",
        "pptx",
        "odt",
        "pdf"
      ];
      let result = null;
      return types.includes(extension);
    },

    viewImage(src, evt) {
      let timeout;
      const eventx =
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch)
          ? "touchstart"
          : "click";
      if (eventx === "click") {
        this.viewActive = true;
        this.viewSrc = src;
      } else if (evt.type === "touchend") {
        const currentTime = new Date().getTime();
        const tapLength = currentTime - lastTap;
        clearTimeout(timeout);
        if (tapLength < 500 && tapLength > 0) {
          this.viewActive = true;
          this.viewSrc = src;
          event.preventDefault();
        }
        lastTap = currentTime;
      }
    },
    removeFile(fileToRemove, index) {
      $controller.removeFile(fileToRemove);
      this.base64.splice(index, 1);
      this.files = $controller.files;
    },
    getFiles(e) {
      let fileSize = e.target.files[0].size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb > 20) {
        this.$onpoint.errorModal(
          "Arquivo muito grande, escolha arquivos até 20mb e tente novamente!"
        );
      } else {
        if (this.veriryFile(e.target.files[0].name)) {
          let newFile = {
            Filename: "",
            FileExtension: "",
            base64: ""
          };
          newFile.Filename = e.target.files[0].name.split(".")[0];
          newFile.FileExtension = e.target.files[0].name.split(".").pop();
          getBase64(e.target.files[0]).then(result => {
            newFile.base64 = result;
            newFile.base64 = result;
            this.base64.push(newFile);
          });
          const _this = this;
          const files = e.target.files;
          const count = this.srcs.length - this.itemRemove.length;
          $controller.addFiles(e.target.files, e);
          this.files = $controller.files;
        } else {
          this.$onpoint.errorModal("Arquivo inválido, tente novamente!");
        }
      }
    },
    async upload() {
      this.$vs.loading();
      this.modalUpload = false;
      let result = "";
      let object = {};
      let totalResult = [];
      for (let i = this.base64.length - 1; i >= 0; i--) {
        result = await this.sendDocument(this.base64[i]);
        if (result === "Error") {
          this.$vs.loading.close();
          this.$onpoint.errorModal("Algo deu errado, tente novamente");
        }
        object = {
          name: result.data.Response.Filename,
          size: result.data.Response.Size,
          uniqueIdentifier: result.data.Response.UniqueId,
          extension: result.data.Response.Extension,
          relativePath: result.data.Response.Filename,
          description: result.data.Response.Description
        };
        totalResult.push(object);
      }
      setTimeout(() => {
        this.$emit("callBackUpload", totalResult);
      }, 1000);
    },
    async sendDocument(document) {
      const payload = {
        FileExtension: document.FileExtension,
        Filename: document.Filename,
        Base64Data: document.base64
      };
      return instance({
        method: "post",
        url: "/api/Archive/UploadBlob",
        data: payload
      })
        .then(result => {
          return result;
        })
        .catch(errors => {
          return "Error";
        });
    },
    close() {
      this.$emit("closeModal");
    }
  },
  mounted() {
    if (this.storageInfo.Uri) {
      $controller = AzUpload.newInstance({
        azUri: this.storageInfo.Uri,
        azContainer: this.storageInfo.ContainerName,
        azSignature: this.storageInfo.Signature
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.con-img-upload {
  background: transparent;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
