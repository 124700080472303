<template>
  <section class="fantasy-name">
    <div class="m-8" v-if="(seguradoFantasyName.list || []).length > 0">
      <h3 class="mb-8 text-base md:text-lg lg:text-2x1 xl:text-3x1">
        Caso deseje utilizar outro {{segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'}}  para o Segurado, selecione
        abaixo ou adicione um.
      </h3>
      <div class="" v-if="(seguradoFantasyName.list || []).length <= 2">
        <vs-card
          v-for="(item, index) in seguradoFantasyName.list"
          :key="item.Id"
        >
          <div class="flex items-center p-5">
            <div class="fantasy-radio w-full flex flex-col flex-grow">
              <label>
                <input
                  @click="toggleFantasyName(item)"
                  type="radio"
                  name="radio"
                  v-model="fantasyNameSelecionado"
                  :value="item.Id"
                />
                <span class="text-base font-semibold">{{ item.Name }}</span>
              </label>
            </div>
            <vs-button
              v-if="item.IsMainName != true"
              radius
              color="primary"
              type="flat"
              @click="removeFantasyName(item, index)"
              size="large"
              icon="delete"
            ></vs-button>
          </div>
        </vs-card>
      </div>
      <vs-collapse class="custom-tab-fantasy" type="border" v-else>
        <vs-collapse-item>
          <div slot="header">
            {{ seguradoFantasyName.list.length || [] }} Nomes Fantasia
            encontrados.
          </div>
          <vs-card
            v-for="(item, index) in seguradoFantasyName.list"
            :key="item.Id"
          >
            <div class="flex items-center p-5">
              <div class="fantasy-radio w-full flex flex-col flex-grow">
                <label>
                  <input
                    @click="toggleFantasyName(item)"
                    type="radio"
                    name="radio"
                    v-model="fantasyNameSelecionado"
                    :value="item.Id"
                  />
                  <span class="text-base font-semibold">{{ item.Name }}</span>
                </label>
              </div>

              <vs-button
                v-if="item.IsMainName != true"
                radius
                color="primary"
                type="flat"
                @click="removeFantasyName(item, index)"
                size="large"
                icon="delete"
              ></vs-button>
            </div>
          </vs-card>
        </vs-collapse-item>
      </vs-collapse>
    </div>
    <div class=" flex flex-row-reverse">
      <vs-button
        type="flat"
        icon="add_circle"
        :icon-after="true"
        @click="includeNewFantasyName()"
      >
        Incluir  {{segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'}} </vs-button
      >
    </div>
    <vs-popup class="nomefantasia" title="" :active.sync="popupNomeFantasia">
      <h3 class="pl-4 pb-6" v-if="segurado.Entity === 'J'">Nome Fantasia</h3>
      <h3 class="pl-4 pb-6" v-else>Nome Social</h3>

      <div class="p-4 pt-0">
        <div class="vx-col" v-if="segurado.Entity === 'J'">
          <ValidationProvider
            name="Nome Fantasia"
            rules="required"
            v-slot="{ errors }"
            ref="fantasyNameRegex"    
          >
            <vs-input
              label="Novo nome fantasia"
              size="large"
              class="w-full"
              v-model="novoNomeFantasia"
              @input="validatedFantasyName(novoNomeFantasia)"
            />
            <span class="text-danger " v-show="errors[0]">
              {{ errors[0] }}
            </span>
            <div class="p-4 mt-5 flex flex-row-reverse w-full">
              <vs-button @click="saveFantasyName()" :disabled=" errors[0] || !validatedFantasyName(novoNomeFantasia) || novoNomeFantasia === '' "
                >Salvar Nome Fantasia</vs-button
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="vx-col" v-else>
          <ValidationProvider
            name="Nome Social"
            rules="required"
            v-slot="{ errors }"
          >
            <vs-input
              label="Novo nome Social"
              size="large"
              class="w-full"
              v-model="novoNomeFantasia"
            />
            <span class="text-danger " v-show="errors[0]">
              {{ errors[0] }}
            </span>
            <div class="p-4 mt-5 flex flex-row-reverse w-full">
              <vs-button @click="saveFantasyName()" :disabled="errors[0]"
                >Salvar Nome Social</vs-button
              >
            </div>
          </ValidationProvider>
        </div>
      </div>
    </vs-popup>
  </section>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import axiosInstance from "../../axios";
export default {
  name: "Fake Name",
  props: {
    segurado: {}
  },
  data() {
    return {
      seguradoFantasyName: {
        id: false,
        list: [],
        name: "",
        selected: []
      },
      novoNomeFantasia: "",
      popupNomeFantasia: false,
      fantasyNameSelecionado: [],
      additional: []
    };
  },
  watch: {
    segurado: {
      immediate: true,
      handler() {
        this.searchFantasyName();
      }
    }
  },
  methods: {
    ...mapMutations("proposta-module", [
      "updateFantasyName",
      "updateFantasyNameProposal"
    ]),
    ...mapActions("proposta-module", ["buscaNomeFantasiaPorId"]),
    searchFantasyName() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(`/api/person/GetFantasyNames?PersonId=${this.segurado.Id}`)
          .then(response => {
            this.seguradoFantasyName.list = response.data.Response;
          })
          .catch(error => {
            this.cep = "";
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    saveFantasyName() {
      return this.$onpoint.loading(() => {
        let data = {
          PersonId: this.segurado.Id,
          Name: this.novoNomeFantasia
        };
        return axiosInstance({
          method: "post",
          url: "/api/person/SaveFantasyName",
          data: data
        })
          .then(response => {
            this.novoNomeFantasia = "";
            this.popupNomeFantasia = false;
            this.$onpoint.successModal( this.segurado.Entity === 'J' ?  "Nome Fantasia cadastrado com sucesso.": "Nome Social cadastrado com sucesso!");
            this.searchFantasyName();
          })
          .catch(response => {
            this.$onpoint.errorModal(response.data.Errors);
          });
      });
    },
    validatedFantasyName(input){    
      const regex = /^[0-9a-zÀ-ÿA-Z\(\)\-\– \.]*$/

      const provider = this.$refs['fantasyNameRegex'];

      if(regex.test(input)){
       this.novoNomeFantasia = input.replace('–', '-') 
        return true
      }
      else{
        provider.errors = ['Não são aceitos caractéres especiais']
        return false
      }   
    },
    removeFantasyName(item, index) {
      this.$onpoint.loadingModal("Processando sua solicitação dados", () => {
        return axiosInstance
          .delete(`/api/person/RemoveFantasyName?fantasyNameId=${item.Id}`)
          .then(() => {
            this.searchFantasyName();
            if (item.Id == this.fantasyNameSelecionado) {
              this.fantasyNameSelecionado = [];
              this.updateFantasyNameProposal(
                this.$store.state["proposta-module"].fantasyName
              );
            }
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
    includeNewFantasyName() {
      this.novoNomeFantasia = "";
      this.popupNomeFantasia = true;
    },
    toggleFantasyName(item) {
      if (this.fantasyNameSelecionado == item.Id) {
        this.fantasyNameSelecionado = [];
        this.updateFantasyNameProposal(
          this.$store.state["proposta-module"].fantasyName
        );
      } else {
        this.fantasyNameSelecionado = item;
        this.updateFantasyNameProposal(item.Name);
      }
    }
  },
  mounted() {
    this.searchFantasyName();
  }
};
</script>

<style lang="scss">
.fantasy-name .icon-header {
  color: rgba(var(--vs-primary), 1) !important;
}
.fantasy-name .icon-header i {
  font-size: 33px !important;
}
.fantasy-radio input[type="radio"] {
  display: none;
}
.fantasy-radio label {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}
.fantasy-radio label span {
  position: relative;
  line-height: 22px;
}
.fantasy-radio label span:before,
.fantasy-radio label span:after {
  content: "";
}
.fantasy-radio label span:before {
  border: 2px solid rgb(180, 180, 180);
  width: 18px;
  height: 18px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  border-radius: 2px;
}

.fantasy-radio label span:after {
  background: rgba(var(--vs-primary), 1);
  width: 22px;
  height: 22px;
  position: absolute;
  top: -2px;
  left: 0px;
  transition: 300ms;
  opacity: 0;
  content: "\2713";
  color: white;
  text-align: center;
  border-radius: 2px;
}
.fantasy-radio label input:checked + span:after {
  opacity: 1;
}
</style>
