<template>
  <div class="mt-12">
    <vs-row>
      <vs-card
        class="beneficiary-card"
        v-for="(item, index) in proposta.Beneficiaries"
        :key="index"
      >
        <div class="info-content">
          <span class="info-container">
            <p class="title-info">
              {{  item.CpfCnpj.length > 11  ? 'Razão Social' : 'Nome'}} : {{  item.Name }} 
            </p>
            <p class="title-info" v-if="item.NomeSocial">
                {{  item.CpfCnpj.length > 11  ? 'Nome Fantasia' : 'Nome Social'}}  : {{    item.NomeSocial }} 
            </p>
            <p class="subtitle-info">{{ $utils.maskDocument(item.CpfCnpj) }}</p>
          </span>
          <p class="percent-info">{{ item.PercentageAmountValue }}%</p>
        </div>

        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button
              class="delete-beneficiary-btn"
              type="filled"
              color="danger"
              icon="delete_outline"
              @click="removeBeneficiary(item)"
            ></vs-button>
          </vs-row>
        </div>
      </vs-card>
    </vs-row>

    <div class="vx-row">
      <div class="vx-col w-full justify-end flex">
        <vs-button class="p-0" type="flat" @click="active = true">
          <i class="onpoint-plus icon-font"></i> Adicionar beneficiário
        </vs-button>
      </div>
    </div>

    <ValidationObserver v-slot="{ invalid }">
      <popup-base
        @close="active = false"
        :showModal="active"
        size="lg"
        id="beneficiary-details-modal"
        title="Adicionar beneficiário"
        :button-close-hidden="false"
      >
        <template slot="content">
          <vs-row>
            <vs-col class="w-full">
              <multiselect
                v-model="cpfCnpjRazaoSocial"
                class="w-full"
                id="buscar-beneficiario"
                label="Name"
                open-direction="bottom"
                :placeholder="
                  allowedPF
                    ? 'Digite o CPF, CNPJ ou Razão Social para buscar um Beneficiário'
                    : 'Digite o CNPJ ou Razão Social para buscar um Beneficiário'
                "
                :options="beneficiariesResult || []"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="300"
                :limit="3"
                :max-height="600"
                :show-no-options="false"
                :show-no-results="true"
                :hide-selected="true"
                @search-change="getBeneficiary"
                select-label=""
              >
                <template slot="option" slot-scope="props">
                  <div
                    class="option__desc"
                    @click="beneficiaryValidation(props.option.DocumentNumber)"
                  >
                    <span class="option__title">
                      <b>{{ props.option.Name }}</b>
                    </span>
                    <p
                      class="mt-3"
                      v-if="$utils.maskDocument(props.option.DocumentNumber)"
                    >
                      {{ $utils.maskDocument(props.option.DocumentNumber) }}
                    </p>
                    <p class="mt-3" v-if="props.option.Description">
                      {{ props.option.Description }}
                    </p>
                  </div>
                </template>
                <template slot="clear" slot-scope="props">
                  <div
                    class="multiselect__clear"
                    @mousedown.prevent.stop="clearAll(props.search)"
                  ></div>
                </template>
                <span slot="noResult"
                  >Não foi encontrado nenhum beneficiário na base</span
                >
              </multiselect>
            </vs-col>
          </vs-row>

          <template v-if="!!(beneficiary || {}).UniqueId">
            <vs-row>
              <vs-col vs-w="12">
                <h3 class="">Dados do beneficiário</h3>
              </vs-col>

              <vs-col vs-lg="6" vs-sm="12">
                <vs-input
                  disabled
                  :label="beneficiary.Entity == 'F' ? 'CPF' : 'CNPJ'"
                  :value="$utils.maskDocument(beneficiary.CpfCnpj)"
                  class="w-full"
                  size="large"
                />
              </vs-col>

              <vs-col vs-lg="6" vs-sm="12">
                <div class="group-edit"   v-if="editName" >
                  <vs-input style="padding-right: 35px !important;"
                    disabled
                    :label="
                      beneficiary.Entity == 'F'
                        ? 'Nome completo'
                        : 'Razão social'
                    "
                    v-model="beneficiary.Name"
                    class="w-full"
                    size="large"
                  />
                  <i
                    v-if="podeHabilitarCadastroNomeSocialBeneficiario"
                    @click.prevent="editName = false"
                    style="margin-left: 5px; color: #083d63; cursor: pointer; position: absolute; top: 35px; right: 45px;"
                    class="onpoint-pencil-simple-line icon-font  float-right text-lg "
                  ></i>
                </div>
                <div class="group-edit"   v-else>
                  <vs-input
                    @blur="insertName()"
                    :label="
                      beneficiary.Entity == 'F'
                        ? 'Nome Social'
                        : 'Nome Fantasia'
                    "
                    v-model="nameEdit"
                    class="w-full"
                    size="large"
                  />
                </div>
              </vs-col>
            </vs-row>

            <vs-row v-if="((beneficiary || {}).Locations || []).length">
              <vs-col vs-w="12">
                <vs-input
                  disabled
                  label="Endereço"
                  :value="
                    (beneficiary.Locations.filter(x => x.IsMain)[0] || {})
                      .Address ||
                      (beneficiary.Locations.filter(x => x.Address)[0] || {})
                        .Address
                  "
                  class="w-full"
                  size="large"
                />
              </vs-col>
            </vs-row>

            <form-endereco
              v-else
              :dadosEndereco="beneficiary.NewAddress"
              @updateValue="beneficiary.NewAddress = $event"
            />

            <vs-row>
              <vs-col vs-lg="6" vs-sm="12">
                <ValidationProvider
                  name="% do seguro"
                  :rules="{
                    required: true,
                    minNumber: 1,
                    maxNumber: maxPercent
                  }"
                  v-slot="{ errors }"
                >
                  <vs-input
                    label="% do seguro"
                    v-model.number="beneficiary.PercentageAmountValue"
                    :max="100"
                    :min="1"
                    class="w-full"
                    size="large"
                  />

                  <span class="text-danger text-sm" v-show="errors[0]">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </vs-col>
            </vs-row>
          </template>
        </template>

        <template slot="footer">
          <vs-row class="p-0">
            <vs-col vs-w="12" class="justify-between flex">
              <vs-button
                class="ml-4 default"
                type="border"
                @click="closeModal()"
                >Cancelar
              </vs-button>

              <vs-button
                color="success"
                class="ml-4"
                type="filled"
                :disabled="invalid"
                @click="addBeneficiary()"
                >Adicionar
              </vs-button>
            </vs-col>
          </vs-row>
        </template>
      </popup-base>
    </ValidationObserver>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters, mapMutations } from "vuex";
import FormEndereco from "@/components/enderecos/FormEndereco.vue";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  props: {
    allowedPF: { type: Boolean, default: false },
    podeHabilitarCadastroNomeSocialBeneficiario: { type: Boolean, default: false }
  },
  components: {
    Multiselect,
    FormEndereco
  },
  data() {
    return {
      active: false,
      isLoading: false,
      cpfCnpjRazaoSocial: "",
      beneficiary: {},
      beneficiariesResult: [],
      editName: true,
      nameEdit: null
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),

    maxPercent() {
      var total = 100;
      (this.proposta.Beneficiaries || []).forEach(
        item => (total -= item.PercentageAmountValue)
      );

      return total;
    }
  },
  methods: {
    ...mapActions("beneficiario-module", [
      "searchBeneficiary",
      "validateBeneficiaryByDocument",
      "saveBeneficiaryLocation"
    ]),
    ...mapMutations("proposta-module", ["updateBeneficiariesProposal"]),
    insertName() {
      console.log(this.nameEdit);
      this.beneficiary.NomeSocial = this.nameEdit;
    },
    getBeneficiary(textSearch) {
      if (textSearch.length < 4) return this.clearAll();

      setTimeout(() => {
        var payload = { textSearch: textSearch, allowedPF: this.allowedPF };
        this.isLoading = true;
        this.searchBeneficiary(payload)
          .then(result => {
            this.beneficiariesResult = result;
            this.isLoading = false;
          })
          .catch(errors => {
            this.isLoading = false;
          });
      }, 500);
    },

    beneficiaryValidation(document) {
      this.$onpoint.loading(() => {
        return this.validateBeneficiaryByDocument({
          BeneficiaryDocument: document,
          InsuredDocument: this.proposta.Insured.CpfCnpj,
          PolicyHolderDocument: this.proposta.PolicyHolder.CpfCnpj,
          CanHaveBeneficiaryPF: this.allowedPF
        })
          .then(result => {
            this.beneficiary = result;

            if (
              !this.beneficiary.LocationId &&
              !(this.beneficiary.Locations || []).length
            ) {
              this.beneficiary.NewAddress = {};
              this.beneficiary.Locations = [];
            }

            this.cpfCnpjRazaoSocial = "";
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    addBeneficiary() {
      console.log("aqui", this.beneficiary);
      this.$onpoint
        .loading(() => {
          if (
            !this.beneficiary.LocationId &&
            !(this.beneficiary.Locations || []).length
          )
            return this.saveBeneficiaryLocation({
              ...this.beneficiary.NewAddress,
              PersonId: this.beneficiary.Id
            })
              .then(result => {
                this.beneficiary.LocationId = result[0].LocationId;
                this.beneficiary.Locations.push(result[0]);
              })
              .catch(errors =>
                this.$onpoint.errorModal(errors.response.data.Errors)
              );
          else return Promise.resolve();
        })
        .then(() => {
          console.log(
            "filtro:> ", [
            ...(this.proposta.Beneficiaries || []).filter(
              x => x.Id != this.beneficiary.Id
            ),
            ...[this.beneficiary] ]
          );
          this.updateBeneficiariesProposal([
            ...(this.proposta.Beneficiaries || []).filter(
              x => x.Id != this.beneficiary.Id
            ),
            ...[this.beneficiary]
          ]);

          this.closeModal();
        });
    },

    removeBeneficiary(item) {
      setTimeout(() => {
        this.updateBeneficiariesProposal(
          this.proposta.Beneficiaries.filter(x => x != item)
        );
      }, 100);
    },

    closeModal() {
      this.clearAll();
      this.beneficiary = {};
      this.active = false;
      this.nameEdit = null;
      this.editName = true;
    },

    clearAll() {
      this.beneficiariesResult = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.vs-card--content {
  margin-bottom: 0 !important;
}

.info-content {
  display: inline-flex;
  width: 100%;
  .info-container {
    width: 70%;
    .title-info {
      font-weight: 600;
      font-size: 14px;
    }
    .subtitle-info {
      font-size: 11px;
      white-space: nowrap;
    }
  }

  .percent-info {
    width: 30%;
    text-align: center;
    align-self: center;
    font-size: 18px;
    font-weight: 600;
  }
}
.group-edit {
  position: relative;
}
</style>

<style lang="scss">
#beneficiary-details-modal {
  .vs-popup {
    min-height: 40vh !important;
    min-width: 50vw !important;

    .vs-popup--content,
    .vs-popup--content .content-popup-base {
      min-height: 30vh;

      .multiselect__content-wrapper {
        max-height: 22vh !important;
      }
    }
  }
}

.delete-beneficiary-btn {
  .vs-button--icon {
    font-size: 20px !important;
  }
}

.beneficiary-card .vs-card--footer {
  .vs-row {
    padding-top: 0 !important;
  }
}

.vs-card--content {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
  position: relative;
  right: 1px;
  top: 1px;
}
</style>