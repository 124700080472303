var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      attrs: {
        title: "",
        active: _vm.enviarParaSeguradoraModal,
        id: "step-rules",
        "button-close-hidden": true,
      },
      on: {
        "update:active": function ($event) {
          _vm.enviarParaSeguradoraModal = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "px-6 pb-6" },
        [
          _c("h3", { staticClass: "text-primary" }, [
            _vm._v("Processando " + _vm._s(_vm.labelModal)),
          ]),
          _c("h6", { staticClass: "mb-10" }, [
            _vm._v(
              "Aguarde, estamos processando sua " + _vm._s(_vm.labelModal) + "."
            ),
          ]),
          _vm._l(_vm.stepList, function (step, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("p", { class: ["flex", "font-semibold"] }, [
                  _c("span", {
                    staticClass:
                      "mr-2 loading-step-block vs-con-loading__container",
                    class: [`loading-step-${step.status}`],
                    attrs: { id: [`loading-step-${step.name}`] },
                  }),
                  step.status == "error"
                    ? _c(
                        "span",
                        { staticClass: "mr-2 material-icons text-danger" },
                        [_vm._v("\n          cancel\n        ")]
                      )
                    : _vm._e(),
                  step.status == "success"
                    ? _c(
                        "span",
                        { staticClass: "mr-2 material-icons text-success" },
                        [_vm._v("\n          check_circle\n        ")]
                      )
                    : _vm._e(),
                  step.status == "warning"
                    ? _c(
                        "span",
                        { staticClass: "mr-2 material-icons text-warning" },
                        [_vm._v("\n          warning\n        ")]
                      )
                    : _vm._e(),
                  _c("span", { staticClass: "pt-2 pl-5" }, [
                    _vm._v(_vm._s(step.message)),
                  ]),
                ]),
                _c(
                  "vs-alert",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      color: "warning",
                      title: "",
                      active: step.status == "warning",
                    },
                  },
                  _vm._l(step.alerts, function (message, index) {
                    return _c(
                      "p",
                      {
                        key: index,
                        staticClass: "text-warning flex font-semibold mt-2",
                      },
                      [
                        _c("span", { staticClass: "mr-2 material-icons" }, [
                          _vm._v(" warning "),
                        ]),
                        _c("span", { staticClass: "mt-1" }, [
                          _vm._v(_vm._s(message)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "vs-alert",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      color: "danger",
                      title: "",
                      active: step.status == "error",
                    },
                  },
                  _vm._l(step.error, function (message, index) {
                    return _c(
                      "p",
                      {
                        key: index,
                        staticClass: "text-danger flex font-semibold mt-2",
                      },
                      [
                        _c("span", { staticClass: "mr-2 material-icons" }, [
                          _vm._v(" cancel "),
                        ]),
                        _c("span", { staticClass: "mt-1" }, [
                          _vm._v(_vm._s(message)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            )
          }),
          _vm.showButtons
            ? _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mt-8 px-20 button-default",
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function ($event) {
                          _vm.showLeaveButton
                            ? _vm.$emit("onClickCloseButton")
                            : _vm.$emit("onClickLeaveButton")
                        },
                      },
                    },
                    [_vm._v("Sair\n      ")]
                  ),
                  _vm.showSuccessButton && _vm.showExeption != true
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "mt-8 ml-3",
                          attrs: { color: "primary", type: "filled" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("onClickSuccessButton")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.labelSuccessButton) + "    \n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.showErrorButton
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "mt-8 ml-3",
                          attrs: { color: "primary", type: "filled" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("onClickErrorButton")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.labelErrorButton) + " \n      ")]
                      )
                    : _vm._e(),
                  _vm.showPendingButton
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "mt-8 ml-3",
                          attrs: { color: "primary", type: "filled" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("onClickPendingButton")
                            },
                          },
                        },
                        [_vm._v("Inserir documentos obrigatórios\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }