var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _vm._m(0),
      _vm.viewActive
        ? _c("az-view-upload", { attrs: { src: _vm.viewSrc } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "con-img-upload" },
        [
          _vm._l(_vm.files, function (file, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "img-upload",
                class: {
                  fileError: file.error,
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn-x-file",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.removeFile(file)
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons notranslate",
                        attrs: { translate: "no" },
                      },
                      [_vm._v("\n          clear\n        ")]
                    ),
                  ]
                ),
                _vm.showUploadButton
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-upload-file",
                        class: {
                          "on-progress": file.getProgress(),
                          "ready-progress": file.getProgress() >= 100,
                        },
                        style: {
                          height: `${
                            file.getProgress() > 0
                              ? file.getProgress()
                              : undefined
                          }%`,
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons notranslate",
                            attrs: { translate: "no" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  file.getProgress() >= 100
                                    ? file.error
                                      ? "report_problem"
                                      : "cloud_done"
                                    : ""
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(file.getProgress()) + " %")]),
                      ]
                    )
                  : _vm._e(),
                file.src
                  ? _c("img", {
                      key: index,
                      style: {
                        maxWidth: file.orientation == "h" ? "100%" : "none",
                        maxHeight: file.orientation == "w" ? "100%" : "none",
                      },
                      attrs: { src: file.src },
                      on: {
                        touchend: function ($event) {
                          return _vm.viewImage(file.src, $event)
                        },
                        click: function ($event) {
                          return _vm.viewImage(file.src, $event)
                        },
                      },
                    })
                  : _vm._e(),
                !file.src
                  ? _c("h4", { staticClass: "text-archive" }, [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons notranslate",
                          attrs: { translate: "no" },
                        },
                        [_vm._v("\n          description\n        ")]
                      ),
                      _c("span", [
                        _vm._v(
                          "\n          " + _vm._s(file.name) + "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          (_vm.singleUpload && (_vm.files || []).length < 1) ||
          _vm.singleUpload == false
            ? _c(
                "div",
                {
                  staticClass: "con-input-upload",
                  class: {
                    "on-progress-all-upload": _vm.percent != 0,
                    "is-ready-all-upload": _vm.percent >= 100,
                    "disabled-upload":
                      _vm.$attrs.hasOwnProperty("disabled") || _vm.limit
                        ? _vm.srcs.length - _vm.itemRemove.length >=
                          Number(_vm.limit)
                        : false,
                  },
                },
                [
                  _c(
                    "input",
                    _vm._b(
                      {
                        ref: "fileInput",
                        attrs: {
                          disabled:
                            _vm.$attrs.disabled || _vm.limit
                              ? _vm.srcs.length - _vm.itemRemove.length >=
                                Number(_vm.limit)
                              : false,
                          type: "file",
                        },
                        on: { change: _vm.getFiles },
                      },
                      "input",
                      _vm.$attrs,
                      false
                    )
                  ),
                  _c("span", { staticClass: "text-input" }, [
                    _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
                  ]),
                  _c("span", {
                    staticClass: "input-progress",
                    style: {
                      width: `${_vm.percent}%`,
                    },
                  }),
                  _vm.showUploadButton
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-upload-all vs-upload--button-upload",
                          attrs: {
                            disabled: _vm.filesx.length == 0,
                            type: "button",
                            title: "Upload",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.upload("all")
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons notranslate",
                              attrs: { translate: "no" },
                            },
                            [_vm._v("\n          cloud_upload\n        ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "vs-button",
        {
          staticClass: "ml-8",
          attrs: { disabled: !_vm.base64, size: "small", color: "success" },
          nativeOn: {
            click: function ($event) {
              return _vm.enviarCartaNomeacao()
            },
          },
        },
        [
          _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
            _vm._v("\n      Enviar Carta de nomeação\n    "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "px-6" }, [
      _c("h4", [
        _c("b", { staticStyle: { color: "#626262 !important" } }, [
          _vm._v("Carta de nomeação"),
        ]),
      ]),
      _c("p", { staticClass: "pt-4" }, [
        _vm._v(
          '\n      Abaixo você pode anexar uma carta de nomeação e enviar para avaliação\n      clicando no botão "Enviar carta de nomeação".\n    '
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }