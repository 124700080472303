<template>
  <div class="tomador-data">
    <div class="vx-row px-8 mb-5">
      <h4 class="vx-col w-full flex items-center font-semibold">
        <span class="block"
          >Digite o CNPJ ou Razão Social para buscar um Tomador.</span
        >
        <!-- <vx-tooltip :text="text.textTomador" position="right">
          <i class="material-icons pl-2">info</i>
        </vx-tooltip> -->
      </h4>
      <div class="vx-col w-full mt-5">
        <multiselect
          v-model="cnpjRazaoSocial"
          class="w-full"
          id="buscar-tomador"
          label="Name"
          open-direction="bottom"
          placeholder="Digite o CNPJ ou Razão Social para buscar um Tomador"
          :options="dadosBuscaTomador"
          :searchable="true"
          :loading="isLoading"
          :internal-search="false"
          :clear-on-select="false"
          :options-limit="300"
          :limit="3"
          :max-height="600"
          :show-no-options="false"
          :show-no-results="true"
          :hide-selected="true"
          @search-change="buscarTomador"
          select-label=""
        >
          <template slot="option" slot-scope="props">
            <div
              class="option__desc"
              @click="tomadorAfiancadoPorHolding(props.option.Cnpj)"
            >
              <span class="option__title">
                <b>{{ props.option.Name }}</b>
              </span>
              <p class="mt-3" v-if="props.option.Cnpj">
                <the-mask
                  class="w-full the-mask"
                  :mask="['##.###.###/####-##']"
                  disabled="true"
                  :masked="true"
                  :value="props.option.Cnpj"
                />
              </p>
              <p class="mt-3" v-if="props.option.Description">
                {{ props.option.Description }}
              </p>
              <p
                class="mt-3 text-danger"
                v-if="
                  !props.option.IsNew && props.option.IsNominatedToOtherBroker
                "
              >
                Tomador nomeado para outra corretora
              </p>
              <p
                class="mt-3 text-success"
                v-if="
                  !props.option.IsNew && props.option.IsNominatedToThisBroker
                "
              >
                Tomador nomeado para sua corretora
              </p>
            </div>
          </template>
          <template slot="clear" slot-scope="props">
            <div
              class="multiselect__clear"
              @mousedown.prevent.stop="clearAll(props.search)"
            ></div>
          </template>
          <span slot="noResult">Não foi encontrado nenhum tomador na base</span>
        </multiselect>
      </div>
    </div>

    <template v-if="!!dadosTomador.CpfCnpj">
      <div class="vx-row px-8">
        <div class="vx-col md:w-1/2 w-full mt-5">
          <p>Razão Social</p>
          <vs-input
            disabled
            :value="dadosTomador.Name"
            class="w-full"
            size="large"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <p>CNPJ</p>
          <the-mask
            class="w-full input-the-mask"
            :mask="['##.###.###/####-##']"
            disabled="true"
            :masked="true"
            :value="dadosTomador.CpfCnpj"
          />
        </div>

        <div class="vx-col w-full mt-5">
          <p>Endereço completo</p>
          <vs-input
            disabled
            :value="
              (endereco || {}).Address ||
              (dadosTomador.Locations || [])[0].Address
            "
            class="w-full"
            size="large"
          />
          <!-- <vs-input v-if="endereco.Address" disabled :value="endereco.Address" class="w-full" size="large" />
          <vs-input v-if="!endereco.Address" disabled :value="endereco.AddressName" class="w-full" size="large" /> -->
        </div>
      </div>
      <div
        class="text-right mt-5 px-8 w-full"
        v-if="
          !dadosTomador.IsNominatedToOtherBroker &&
          !dadosTomador.HasPendingNomeationProcess &&
          dadosTomador.Id > 0
        "
      >
        <router-link
          :to="{
            name: 'detalhe-tomador',
            params: { tomadorUniqueId: dadosTomador.UniqueId },
          }"
          target="_blank"
        >
          <vs-button color="primary" type="flat">
            <span class="font-semibold"> Visualizar cadastro completo </span>
          </vs-button>
        </router-link>
      </div>

      <div class="text-right mt-5 px-8 w-full" v-if="podeSolicitarLimite">
        <vs-button
          color="primary"
          type="flat"
          @click="popupDetalhamento = true"
        >
          <span class="font-semibold"> Solicitar revisão de limite </span>
        </vs-button>
      </div>

      <div class="m-8" v-if="(filiais || []).length > 0">
        <h3 class="mb-8 text-base md:text-lg lg:text-2x1 xl:text-3x1">
          Caso deseje utilizar uma filial, selecione abaixo ou adicione uma.
        </h3>
        <vs-card v-for="(filial, index) in filiais" :key="filial.Id">
          <div class="flex items-center p-5">
            <vs-checkbox
              @change="toggleFilial(filial)"
              v-model="filialSelecionada[filial.Id]"
              class="w-full"
            >
              <div class="ml-2 mr-3 flex flex-col flex-grow">
                <p class="text-base font-semibold">{{ filial.Name }}</p>
                <the-mask
                  class="text-base font-semibold the-mask"
                  :mask="['##.###.###/####-##']"
                  readonly
                  :masked="true"
                  :value="filial.CpfCnpj"
                />
              </div>
            </vs-checkbox>
            <!--<vs-radio v-model="filialSelecionada" class="w-full" vs-name="radios1" :value="filial.CpfCnpj">
              <div class="ml-2 mr-3 flex flex-col flex-grow">
                <p class="text-base font-semibold">{{filial.Name}}</p>
                <the-mask class="text-base font-semibold the-mask" :mask="['##.###.###/####-##']"
                  readonly :masked="true" :value="filial.CpfCnpj" />
              </div>
            </vs-radio>-->
            <vs-button
              radius
              color="primary"
              type="flat"
              @click="excluirFilial(filial.UniqueId, index)"
              size="large"
              icon="delete"
            ></vs-button>
          </div>
        </vs-card>
      </div>
      <div
        class="text-right mt-5 px-8 w-full"
        v-if="!dadosTomador.IsNominatedToOtherBroker"
      >
        <adicionar-filial
          :uniqueIdTomador="dadosTomador.UniqueId"
          @modal_close="onModalClose"
        />
      </div>

      <div class="limites" v-if="showLimites">
        <vs-table
          :data="(limitesETaxas || {}).LimitAndRates || []"
          noDataText="Nenhum Limite e Taxas encontrado."
          :hoverFlat="true"
        >
          <template slot="header">
            <h4 class="px-8 pb-4">
              <b>Limites e Taxas</b>
            </h4>
          </template>
          <template slot="thead">
            <vs-th> Modalidades </vs-th>
            <vs-th> Limite Aprovado <span class="text-danger">*</span> </vs-th>
            <vs-th> Limite Disponível </vs-th>
            <vs-th>
              Taxa <br />
              Segurado privado/público
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].ModalityGroupName">
                <div class="flex">
                  <span class="ml-20"
                    >{{ data[indextr].ModalityGroupName }}
                    <span v-if="!data[indextr].CanViewLimit" class="text-danger"
                      >*</span
                    ></span
                  >
                  <!-- <vx-tooltip :text="data[indextr].ModalityGroupDescription">
                    <span class="material-icons text-warning text-sm ml-2"
                      >info</span
                    >
                  </vx-tooltip> -->
                </div>
              </vs-td>

              <vs-td :data="data[indextr].LimitRevised">
                <currency-input
                  v-if="data[indextr].CanViewLimit"
                  class="input-currency text-primary font-semibold"
                  v-model="data[indextr].LimitRevised"
                  disabled="true"
                />
                <br />
                <small
                  class="text-danger"
                  v-if="
                    data[indextr].ChangedManually &&
                    !perfilHelpers.isInsurance()
                  "
                  >* Limite alterado pela seguradora</small
                >
              </vs-td>

              <vs-td :data="data[indextr].AvailableLimit">
                <currency-input
                  v-if="data[indextr].CanViewLimit"
                  class="input-currency text-primary font-semibold"
                  v-model="data[indextr].AvailableLimit"
                  disabled="true"
                />
              </vs-td>

              <vs-td :data="data[indextr].RiskRate">
                <p v-if="data[indextr].CanViewLimit">
                  {{ data[indextr].RiskRate }}% / {{ data[indextr].RiskRate }}%
                </p>
              </vs-td>
            </vs-tr>
            <vs-tr state="warning">
              <vs-td>
                <p class="font-bold">Limite Total</p>
              </vs-td>
              <vs-td>
                <currency-input
                  class="input-currency font-bold"
                  v-model="limitesETaxas.MaxApprovedLimit"
                  disabled="true"
                />
              </vs-td>
              <vs-td>
                <currency-input
                  class="input-currency font-bold"
                  v-model="limitesETaxas.MaxAvailableLimit"
                  disabled="true"
                />
              </vs-td>
              <vs-td></vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="px-8 pb-8">
          <h5 v-if="!Sombrero">Informações adicionais</h5>
          <b>
            <div class="Excelsior" v-if="Excelsior">
              <p>
                * As aprovações acima são indicativas e podem sofrer alterações;
              </p>
              <p>
                ** À critério da Seguradora, pode ser solicitada documentação
                adicional em qualquer modalidade
              </p>
              <p>
                *** Nas modalidades Judiciais: Não existe automaticidade, 100%
                das operações passarão pela análise julgamental para confirmação
                ou não do limite. Somente serão analisados os clientes que
                atinjam os critérios mínimos de Faturamento Anual / PL abaixo: -
                Judicial Fiscal: R$ 50 MM de Fat. Ano e PL ou R$ 200 MM de Fat.
                Ano - Judicial Cível: R$ 200 MM de Fat. Ano ou R$ 200 MM de PL
              </p>
              <p>
                **** Nas modalidades Financeiras: Automaticidade até R$ 500 mil
                de IS, acima deste valor, necessário envio dos balanços para
                análise julgamental, para confirmação ou não do limite. -
                Energia: Guideline mínimo de: Fat. Ano e PL de R$ 20 MM.
              </p>
              <p></p>
              <p></p>
            </div>
            <div class="Excelsior" v-else-if="Sombrero">
              <p>Informações adicionais: <br /><br /></p>
              <p>
                *As aprovações acima são indicativas e podem sofrer alterações;
              </p>
              <p>
                **À critério da Seguradora, pode ser solicitada documentação
                adicional em qualquer modalidade;
              </p>
              <p>
                Nas modalidades Judiciais:<b style="text-decoration: underline">
                  NÃO EXISTE AUTOMATICIDADE</b
                >, 100% das operações passarão pela análise julgamental para
                confirmação ou não do limite. Somente serão analisados os
                clientes que atinjam aos critérios mínimos de Faturamento Anual/
                PL: JUDICIAL FISCAL: R$ 200MM de Fat. Ano e PL 100MM – JUDICIAL
                CÝVEL E TRABALHISTA: R$ 35MM Fat. Ano e PL R$ 35MM.
              </p>
              <p></p>
              <p></p>
            </div>
            <div v-else-if="Essor">
              <p>* As aprovações acima são indicativas e podem sofrer alterações;</p>
              <p>
                *Tomadores com limites zerados em algum grupo de modalidades podem solicitar revisão de limite diretamente na Plataforma,
                 neste primeiro passo, clicando em “Revisar Limite”.
              </p>
              <p>
                *Tomadores que possuam demandas que excedam o valor do limite aprovado,
                e ainda não revisado nos últimos 6 meses, poderão solicitar revisão 
                diretamente pela Plataforma, seguindo o fluxo da proposta.
              </p>
              <p></p>
              <p></p>
            </div>            
            <p
              v-else
              v-for="(info, index) in informacoesAdicionaisLimites"
              :key="index"
              style="font-size: 0.9em !important"
            >
              {{ "*".repeat(info.order) }} {{ info.value
              }}{{
                index == informacoesAdicionaisLimites.length - 1 ? "." : ";"
              }}
            </p>
            <br />
          </b>
        </div>

        <p class="px-32 pt-4 pb-12" v-if="canRequestLimitsAndRatesReview">
          <span class="text-warning">*</span>
          Limite pré-aprovado e provisorio, pode estar aquém da capacidade do
          tomador. Para aprovação do limite completo e definitivo é preciso a
          inclusão da demanda e o envio da documentação financeira
        </p>
      </div>
    </template>

    <div id="loading-limites" class="hidden">
      <div class="py-12 px-32 flex items-center">
        <div id="proposta-loading" class="vs-con-loading__container"></div>
        <div class="pl-20">
          <h4>Aguarde, estamos calculando o limite e a taxa para o tomador</h4>
          <p>
            Esse processo pode levar alguns minutos. Caso queira, pode continuar
            com o preenchimento da proposta, enquanto realizamos este
            procedimento
          </p>
        </div>
      </div>
    </div>

    <template>
      <div
        class="vx-row px-8"
        v-if="
          dadosTomador.Id > 0 &&
          dadosTomador.IsNominatedToOtherBroker &&
          !dadosTomador.HasPendingNomeationProcess
        "
      >
        <div class="vx-col w-full mt-2">
          <vs-alert color="danger">
            Esse tomador está nomeado para outra corretora. Para prosseguir com
            uma proposta, anexe uma carta de nomeação para submeter ao processo
            de nomeação.
          </vs-alert>
        </div>
      </div>

      <div class="vx-row px-8 mt-8">
        <carta-nomeacao
          v-if="
            this.dadosTomador.CpfCnpj &&
            dadosTomador.Id > 0 &&
            !dadosTomador.HasPendingNomeationProcess &&
            !dadosTomador.IsNominatedToThisBroker
          "
          :tomador="this.dadosTomador"
          :nomeationRequestCallback="this.nomeationRequestCallback"
        />
      </div>

      <div
        class="vx-row px-8"
        v-if="dadosTomador.Id > 0 && dadosTomador.HasPendingNomeationProcess"
      >
        <div class="vx-col w-full mt-5">
          <vs-alert color="warning">
            <b>Atenção!</b> Você já possui uma solicitação de nomeação via carta
            para esse tomador.
            <br />
            Data da solicitação:
            <b>{{
              dadosTomador.LastPolicyHolderNomeationRequest.RequestDate
                | moment("DD/MM/YYYY")
            }}</b>
          </vs-alert>
        </div>
      </div>

      <div
        class="vx-row px-8"
        v-if="dadosTomador.Id > 0 && dadosTomador.IsNominatedToThisBroker"
      >
        <div class="vx-col w-full mt-5">
          <vs-alert color="success">
            Esse tomador está nomeado para sua corretora.
          </vs-alert>
        </div>
      </div>
    </template>

    <vs-popup
      class="endereco"
      :buttonCloseHidden="true"
      title=""
      :active="popupAddEndereco"
    >
      <h3 class="pl-4 pb-6">Novo Endereço</h3>
      <vs-alert color="warning" class="pl-4 h-auto mt-2 mb-3">
        Não encontramos nenhum endereço vinculado. Por favor, adicione para
        continuar.
      </vs-alert>
      <div class="p-4 pt-0">
        <div class="vx-col">
          <vs-input
            label="CEP"
            size="large"
            class="w-full"
            v-model="cep"
            v-mask="'#####-###'"
            v-debounce:1s="buscarCep"
          />
        </div>

        <template v-if="novoEndereco.ZipCode">
          <div class="mt-5 flex flex-wrap justify-around">
            <div class="vx-col pr-1 w-3/4 md:pr-4">
              <vs-input
                label="Logradouro"
                size="large"
                class="w-full"
                v-model="novoEndereco.AddressName"
              />
            </div>

            <div class="vx-col w-1/4">
              <vs-input
                label="Número"
                size="large"
                class="w-full"
                v-model="novoEndereco.Number"
              />
            </div>
          </div>

          <div class="mt-5 flex flex-wrap justify-around">
            <div class="vx-col pr-1 w-full md:w-3/5 md:pr-4">
              <vs-input
                label="Complemento"
                size="large"
                class="w-full"
                v-model="novoEndereco.Complement"
              />
            </div>

            <div class="vx-col mt-5 w-full md:w-2/5 md:mt-0">
              <vs-input
                label="Bairro"
                size="large"
                class="w-full"
                v-model="novoEndereco.Neighborhood"
              />
            </div>
          </div>

          <div class="mt-5 flex flex-wrap justify-around md:w-3/5">
            <div class="vx-col pr-1 w-full md:w-1/2 md:pr-4">
              <vs-input
                label="Cidade"
                size="large"
                class="w-full"
                v-model="novoEndereco.CityName"
              />
            </div>

            <div class="vx-col mt-5 w-full md:w-1/2 md:mt-0">
              <vs-input
                label="Estado"
                size="large"
                class="w-full"
                v-model="novoEndereco.StateProvinceName"
              />
            </div>
          </div>

          <div class="p-4 mt-5 flex flex-row-reverse w-full">
            <vs-button @click="salvarendereco(novoEndereco)"
              >Salvar Endereço</vs-button
            >
          </div>
        </template>
      </div>
    </vs-popup>

    <request-limit
      :showModal="popupDetalhamento"
      :detalhamento="detalhamento"
      :tomadorUniqueId="(dadosTomador || {}).UniqueId"
      @closeModal="closeModalDocuments"
      @dismissModal="dismissModalDocuments"
      @updateDocs="updateDocs"
    ></request-limit>

    <!-- <vs-popup
      class="cadastrar-filial"
      title=""
      :active="popupAfiancadoHolding"
    >
      <p class="pl-4 text-primary mb-8">{{ tomadorSelecionado.Message }}</p>
      <p class="pl-4 text-primary mb-8">O tomador é afiançado pela holding?</p>

      <div class="p-4 pt-0 mb-8">
        <div class="vx-col mt-5 text-right">
          <vs-button @click="salvarFilial()">
            Salvar filial
          </vs-button>
        </div>
      </div>
    </vs-popup> -->

    <el-dialog
        width="32%"
        :visible.sync="popupAfiancadoHolding"
        :before-close="fecharPopupAfiancadoHolding"
        class="popup-afiancado-holding"
      >
        <div class="popup-afiancado-holding-content">
          <div class="popup-afiancado-holding-content-header">
            <h4>{{ tomadorSelecionado.Message }}</h4>
          </div>

          <div class="popup-afiancado-holding-content-body">
            <p>Este tomador é afiançado pela holding?</p>
          </div>
        </div>

        <span slot="footer" class="dialog-footer popup-afiancado-holding-footer">
          <vs-button class="popup-afiancado-holding-footer-no-option" @click="enviarStatusAfiancadoPelaHolding(false)">
            Não
          </vs-button>
          <vs-button @click="enviarStatusAfiancadoPelaHolding(true)">
            Sim
          </vs-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mask, TheMask } from "vue-the-mask";
import Multiselect from "vue-multiselect";
import _ from "lodash";
import axiosInstance from "@/axios";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import AdicionarFilial from "@/components/tomador/AdicionarFilial";
import CartaNomeacao from "@/components/tomador/CartaNomeacao.vue";
import RequestLimit from "../RequestLimits.vue";
import environment from "@/environment";
export default {
  name: "dados-tomador",
  directives: { mask },
  props: ["tomadorProposta", "canRequestLimitsAndRatesReview"],
  components: {
    AdicionarFilial,
    Multiselect,
    TheMask,
    CartaNomeacao,
    RequestLimit,
  },
  data() {
    return {
      showDadostomador: false,
      isLoading: false,
      novoTomador: false,
      cnpjNovoTomador: "",
      cnpjRazaoSocial: "",
      dadosBuscaTomador: [],
      filialSelecionada: {},
      popupAddEndereco: false,
      cep: "",
      novoEndereco: {},
      text: {
        textTomador:
          "Incluir o CNPJ do Tomador para cadastrar ou acessar dados já existentes na Seguradora",
      },
      informacoesAdicionaisLimites: [],
      perfilHelpers: PerfilHelpers,
      popupDetalhamento: false,
      detalhamento: [],
      podeSolicitarLimite: false,
      Excelsior: false,
      bailLimitData: {},
      Newe: false,
      Sombrero: false,
      Essor:false,
      isAxa: false,
      popupAfiancadoHolding: false,
      tomadorSelecionado: {}
    };
  },
  watch: {
    proposta: {
      immediate: true,
      handler(val, oldVal) {
        if ((this.proposta || {}).PolicyHolderBranchId > 0) {
          const foundBranch = (
            ((this.proposta || {}).PolicyHolder || {}).Branches || []
          ).find(
            (x) => x.PersonId == (this.proposta || {}).PolicyHolderBranchId
          );
          if (foundBranch && foundBranch.Id > 0)
            this.filialSelecionada[foundBranch.Id] = true;
        }
      },
    },
    edicaoDeProposta: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          setTimeout(this.loadingLimites, 400);
        }
      },
    },
  },
  computed: {
    ...mapGetters("proposta-module", [
      "proposta",
      "limitesETaxas",
      "edicaoDeProposta",
    ]),
    ...mapGetters("tomador-module", [
      "dadosTomador",
      "novaPropostaLimitesETaxasTomador",
    ]),
    dadosTomador: {
      get() {
        const result = (this.proposta || {}).PolicyHolder || {};
        return result;
      },
    },
    showLimites: {
      get() {
        return (
          ((this.limitesETaxas || {}).LimitAndRates || []).length >
          0
        );
      },
    },
    endereco() {
      if (!this.proposta || !this.proposta.PolicyHolder) return {};
      const tomador = this.proposta.PolicyHolder;
      if (!tomador.Locations || tomador.Locations.length == 0) return {};
      const locationMain = tomador.Locations.find(
        (location) => location.IsMain
      );
      if (locationMain) return locationMain;
      else tomador.Locations[0];
    },
    filiais: {
      get() {
        const tomador = (this.proposta || {}).PolicyHolder || {};
        return tomador.Branches || [];
      },
    },

    mostrarDadosFiancaCorretor() {
      let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
      let Newe = parseInt(environment.CompanyId.Newe)
      return this.Newe = portalConfig.CompanyId == Newe  ? true : false ;
    },
  },
  mounted() {
    this.initLoading();
    this.carregaInformacoesAdicionais();
    this.visualizarDetalhamento();
    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Excelsior = parseInt(environment.CompanyId.Excelsior);
    let Sombrero = parseInt(environment.CompanyId.Sombrero);
    let Essor = parseInt(environment.CompanyId.Essor);
    let Axa = parseInt(environment.CompanyId.Axa);

    this.Excelsior = portalConfig.CompanyId == Excelsior ? true : false;
    this.Sombrero = portalConfig.CompanyId == Sombrero ? true : false;
    this.Essor = portalConfig.CompanyId == Essor ? true : false;
    this.isAxa = portalConfig.CompanyId == Axa ? true : false;
  },
  methods: {
    ...mapMutations("proposta-module", [
      "limparEstado",
      "updateTomadorFilialProposta",
      "updateTomadorProposta",
      "updateTomadorPropostaSemAlterarLimiteETaxa",
      "updateProposta",
      "updateLimitesETaxas",
      "updatePropostaFiliaisTomador",
    ]),
    ...mapActions("proposta-module", [
      "buscaTomadorPorTexto",
      "validaTomadorPorCnpj",
      "carregarLimitesETaxas",
    ]),
    ...mapActions("filial-module", ["deletarFilial","cadastrarFilial"]),
    ...mapActions("propostas-module", ["getPropostaByUniqueId"]),

    closeModalDocuments() {
      this.popupDetalhamento = false;
    },
    dismissModalDocuments() {
      this.popupDetalhamento = false;
    },
    updateDocs(docs) {
      this.detalhamento = docs;
    },
    visualizarDetalhamento() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(`api/PolicyHolder/GetRequestLimitParameterDocuments`)
          .then((response) => {
            this.detalhamento = response.data.Response;
          })
          .catch((error) => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },

    nomeationRequestCallback(tomador) {
      this.updateTomadorPropostaSemAlterarLimiteETaxa(tomador);
    },
    async salvarFilial(CpfCnpj) {
     await this.$onpoint.loadingModal(
        "Aguarde, as informações estao sendo processadas",
       async () => {
          const newFilial = {
            cnpj: CpfCnpj,
            uniqueId: this.dadosTomador.UniqueId
          };
          return await this.cadastrarFilial(newFilial)
            .then(response => {
              this.$emit("modal_close", true);
            })
            .catch(error => {
              this.$onpoint.errorModal(error.response.data.Errors);
            })
        }
      );
    },
    toggleFilial(filial) {
      this.salvarFilial(filial.CpfCnpj)
      //filialSelecionada
      Object.entries(this.filialSelecionada).forEach((key) => {
        if (key[0] != filial.Id) {
          this.filialSelecionada[key[0]] = false;
        }
      });

      const checked = this.filialSelecionada[filial.Id];
      if (checked) this.updateTomadorFilialProposta(filial);
      else this.updateTomadorFilialProposta(undefined);
    },
    adicionarFilial() {
      this.adicionarFilialActivo = true;
    },

    onModalClose(event) {
      if (event) this.validarTomador(this.dadosTomador.CpfCnpj);
    },
    async excluirFilial(uniqueId, index) {
      //this.filiais.splice(index, 1)
      await this.$onpoint.loadingModal("Processando sua solicitação", async () => {
        return await this.deletarFilial(uniqueId)
          .then((response) => {
            this.adicionarFilialActivo = false;
            this.updatePropostaFiliaisTomador(response);
          })
          .catch((error) => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },

    clearAll() {
      this.dadosBuscaTomador = [];
    },

    async validarTomador(cnpj) {
      if (!cnpj) return;

      this.$appInsights.trackEvent({ name: "inserir-tomador-proposta", properties: { place: "proposta-passo-1", action: "button-click", slug: "inserir-tomador-proposta-passo-1" }});
      await this.$onpoint.loading(async () => {
        return await this.validaTomadorPorCnpj(this.$utils.removeSpecialChars(cnpj))
          .then((response) => {
            this.isLoading = false;
            if (
              this.dadosTomador &&
              !this.dadosTomador.IsNominatedToOtherBroker
            ) {
              this.loadingLimites();
              if (
                this.dadosTomador.Locations.length == 0 ||
                this.dadosTomador.Locations == null ||
                this.endereco.AddressName == ""
              ) {
                this.popupAddEndereco = true;
              }
            }
            setTimeout(() => {
              this.cnpjRazaoSocial = "";
            }, 200);
          })
          .catch((errors) => {
            this.isLoading = false;
            this.$onpoint.errorModal(errors.response.data.Errors);
            this.cnpjRazaoSocial = "";
          })
          .finally(() => {
            this.isLoading = false;
            window.scrollTo(0, 0);
          });
      });
    },
    delay(callback, ms) {
      let timer = 0;
      return function () {
        const context = this,
          args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          callback.apply(context, args);
        }, ms || 0);
      };
    },
    buscarTomador: _.debounce(async function (query) {
      if (!query || query.length < 3) {
        return this.clearAll();
      }

      this.isLoading = true;
      this.dadosBuscaTomador = [];
      // this.buscaTomadorPorTexto(Utils.removeSpecialChars(query))
      await this.buscaTomadorPorTexto(query)
        .then((response) => {
          this.isLoading = false;
          this.dadosBuscaTomador = response;
        })
        .catch((errors) => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),
    async loadingLimites() {
      const load = document.getElementById("loading-limites");
      if (load && load.classList) load.classList.remove("hidden");

      await this.carregarLimitesETaxas()
        .then(() => {
          if (load && load.classList) load.classList.add("hidden");
          this.bailLimitData = this.limitesETaxas.BailLimit;
          this.podeSolicitarLimite = this.limitesETaxas.CanRequestLimitReview
        })
        .catch((errors) => {
          if (load && load.classList) load.classList.add("hidden");
          this.$onpoint.errorModal(errors.data.Errors);
        });
      /**

      return axiosInstance.post(`/api/PolicyHolder/CalculatePolicyHolderLimitsAndRates?PolicyHolderUniqueId=${this.dadosTomador.UniqueId}`)
        .then(response => {
          this.limitesTotais = response.data.Response
          this.limites = this.limitesTotais.PolicyHolderLimitsAndRates
          this.updateTomadorLimitesETaxas(this.limites);
          this.limteDisponivelAprovado = this.limitesTotais.MaxApprovedLimit
          this.limteTotalAprovado = this.limitesTotais.MaxAvailableLimit
          this.showLimites = true
          load.classList.add('hidden')
        })
        .catch(errors => {
          this.$onpoint.errorModal(errors.data.Errors)
        })**/
    },

    /* calculaTotais () {
      return axiosInstance
        .get(`/api/PolicyHolder/GetLastPolicyHolderLimitsAndRates?PolicyHolderUniqueId=${this.dadosTomador.UniqueId}`)
        .then(response => {
          this.limitesTotais = response.data.Response
        }) */
    /*this.limteDisponivelAprovado = 0
      this.limteTotalAprovado = 0

       limites.reduce((acc, limite) => {
        this.limteDisponivelAprovado += Number(limite.AvailableLimit)
        return this.limteDisponivelAprovado
      }, 0)

      limites.reduce((acc, limite) => {
        this.limteTotalAprovado += Number(limite.AvailableLimit)
        return this.limteTotalAprovado
      }, 0)
    },*/

    initLoading() {
      this.$vs.loading({
        container: "#proposta-loading",
        scale: 2,
        color: "warning",
      });
    },

    carregaInformacoesAdicionais() {
      if (this.informacoesAdicionaisLimites.length == 0) {
        this.$onpoint.loading(() => {
          return axiosInstance
            .get(`/api/core/GetPolicyHolderLimitAndRatesObservation`)
            .then((response) => {
              response = response.data.Response;
              this.informacoesAdicionaisLimites = JSON.parse(response);
            })
            .catch((error) => {
              this.$onpoint.errorModal(error.response.Message);
            });
        });
      }
    },

    salvarendereco() {
      const enderecoNovo = {
        ...this.novoEndereco,
        PersonId: this.proposta.PolicyHolderId,
      };
      if (
        this.dadosTomador.Locations.length == 0 ||
        this.dadosTomador.Locations == null ||
        this.endereco.AddressName == ""
      ) {
        enderecoNovo.IsMain = true;
      }
      return this.$onpoint.loading(() => {
        return axiosInstance({
          method: "post",
          url: "/api/PolicyHolder/SavePolicyHolderLocation",
          data: enderecoNovo,
        })
          .then((response) => {
            this.proposta.PolicyHolder.Locations = response.data.Response;
            this.cep = "";
            this.novoEndereco = {};
            this.popupAddEndereco = false;
            this.$onpoint.successModal("Endereço cadastrado com sucesso.");
          })
          .catch((response) => {
            this.$onpoint.errorModal(response.data.Errors);
          });
      });
    },

    async buscarCep() {
      const cep = this.$utils.removeSpecialChars(this.cep);
      if (!cep || cep.length < 8) return;
      await this.$onpoint.loading(async() => {
        return await axiosInstance
          .get(`/api/Location/SearchCEP?cep=${cep}`)
          .then((response) => {
            this.novoEndereco = response.data.Response;
          })
          .catch((error) => {
            this.cep = "";
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    tomadorAfiancadoPorHolding(cnpj){
      this.tomadorSelecionado = this.dadosBuscaTomador.find(x => x.Cnpj === cnpj)

      if(this.isAxa){
        if(this.tomadorSelecionado.IsNew || this.tomadorSelecionado.HasExpired) {
          this.popupAfiancadoHolding = true
          return
        }
      }
      this.validarTomador(cnpj)
    },

    fecharPopupAfiancadoHolding() {
      this.cnpjRazaoSocial = "";
      this.dadosBuscaTomador = [];
      this.popupAfiancadoHolding = false;
    },

    async enviarStatusAfiancadoPelaHolding(status){
      let tomadorAfiancado = status ? "S" : "N"
      let data = {
        Cnpj: this.cnpjRazaoSocial.Cnpj,
        Options: {
            PROP_AFIANCADO_PELA_MAE: tomadorAfiancado,
        }
      }

      await this.$onpoint.loadingModal("Estamos processando sua resposta", async () => {
        try {
          const response = await axiosInstance.post(`api/PolicyHolder/SetPolicyHolderCacheByCNPJ`, data)
          
          this.popupAfiancadoHolding = false;
          this.validarTomador(this.cnpjRazaoSocial.Cnpj)
        } catch (error) {
          this.fecharPopupAfiancadoHolding()
          this.$onpoint.errorModal(error.response.data.Errors);
        }
      });
      
    }
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/mixins";

.multiselect__content-wrapper {
  width: 97%;
  margin-left: 20px;
}
.multiselect__option {
  background: white;
  color: rgba(var(--vs-primary), 1);
  border-bottom: 1px solid #e8e8e8;
}

.multiselect__option--highlight {
  background: #f1f1f1;
}

.multiselect__option--highlight:after {
  display: none;
}

.multiselect__tags {
  border-radius: 50px !important;
  height: 49.2px;
  padding-top: 10px;
}

.multiselect__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  padding-left: 6px !important;
  padding-top: 0 !important;
}

.multiselect__input {
  font-size: 13px !important;

  @include lg {
    font-size: 16px !important;
  }
}

.proposta {
  td {
    border-top: solid 1px rgba(0, 0, 0, 0.27);
  }

  td {
    padding: 30px !important;

    .info {
      color: rgba(var(--vs-warning), 1);
      font-size: 0.8em;
    }
  }

  .vs-con-tbody {
    border: none !important;
    background: white !important;
  }

  .vs-con-table td {
    text-align: center;
  }

  .vs-table-text {
    justify-content: center !important;
    text-align: center;
  }

  .tr-table-state-warning {
    td {
      color: initial;
      opacity: 0.7;
    }
  }

  .disabled {
    background-color: rgba(239, 239, 239, 0.3);
  }
  .con-vs-loading {
    z-index: 100 !important;
  }
  .buscar-cep {
    height: 47px;
  }

  #proposta-loading {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wizard-tab-content {
    background: white !important;
    margin: 2em 0 0;
    min-height: 130px !important;
  }
}
</style>

<style lang="scss" scoped>
h4 {
  color: rgba(var(--sv-dark), 1);

  i {
    color: rgba(var(--vs-warning), 1);
    display: block;
    font-size: 0.8em;
  }
}

.input-currency {
  border: none;
  background: transparent;
  font-size: initial;
  text-align: center;
}

.link {
  float: right;
}

.popup-afiancado-holding {
  &-content {
    text-align: center;
    &-header {
      & h4 {
        font-size: 18px;
        margin-top: 20px;
      }
    }

    &-body {
      margin-top: 36px;
    }
  }

  &-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 26px 0 16px;

    &-no-option{
      font-weight: 500;
      background: #fff !important;
      border: 1px solid rgba(var(--vs-primary), 1) !important;
      color: #626262;
    }
  }
}
</style>
