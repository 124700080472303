<template>
  <div class="w-full">
    <div class="px-6">
      <h4>
        <b style="color:#626262 !important">Carta de nomeação</b>
      </h4>
      <p class="pt-4">
        Abaixo você pode anexar uma carta de nomeação e enviar para avaliação
        clicando no botão "Enviar carta de nomeação".
      </p>
    </div>

    <az-view-upload v-if="viewActive" :src="viewSrc" />

    <div class="con-img-upload">
      <div
        v-for="(file, index) in files"
        :class="{
          fileError: file.error
        }"
        :key="index"
        class="img-upload"
      >
        <button class="btn-x-file" type="button" @click="removeFile(file)">
          <i translate="no" class="material-icons notranslate">
            clear
          </i>
        </button>
        <button
          v-if="showUploadButton"
          :class="{
            'on-progress': file.getProgress(),
            'ready-progress': file.getProgress() >= 100
          }"
          :style="{
            height: `${
              file.getProgress() > 0 ? file.getProgress() : undefined
            }%`
          }"
          class="btn-upload-file"
        >
          <i translate="no" class="material-icons notranslate">
            {{
              file.getProgress() >= 100
                ? file.error
                  ? "report_problem"
                  : "cloud_done"
                : ""
            }}
          </i>
          <span>{{ file.getProgress() }} %</span>
        </button>
        <img
          v-if="file.src"
          :style="{
            maxWidth: file.orientation == 'h' ? '100%' : 'none',
            maxHeight: file.orientation == 'w' ? '100%' : 'none'
          }"
          :key="index"
          :src="file.src"
          @touchend="viewImage(file.src, $event)"
          @click="viewImage(file.src, $event)"
        />
        <h4 v-if="!file.src" class="text-archive">
          <i translate="no" class="material-icons notranslate">
            description
          </i>
          <span>
            {{ file.name }}
          </span>
        </h4>
      </div>
      <!-- </transition-group > -->

      <div
        :class="{
          'on-progress-all-upload': percent != 0,
          'is-ready-all-upload': percent >= 100,
          'disabled-upload':
            $attrs.hasOwnProperty('disabled') || limit
              ? srcs.length - itemRemove.length >= Number(limit)
              : false
        }"
        v-if="
          (singleUpload && (files || []).length < 1) || singleUpload == false
        "
        class="con-input-upload"
      >
        <input
          ref="fileInput"
          v-bind="$attrs"
          :disabled="
            $attrs.disabled || limit
              ? srcs.length - itemRemove.length >= Number(limit)
              : false
          "
          type="file"
          @change="getFiles"
        />
        <span class="text-input">
          {{ text }}
        </span>
        <span
          :style="{
            width: `${percent}%`
          }"
          class="input-progress"
        >
        </span>
        <button
          v-if="showUploadButton"
          :disabled="filesx.length == 0"
          type="button"
          title="Upload"
          class="btn-upload-all vs-upload--button-upload"
          @click="upload('all')"
        >
          <i translate="no" class="material-icons notranslate">
            cloud_upload
          </i>
        </button>
      </div>
    </div>

    <vs-button
      :disabled="!base64"
      @click.native="enviarCartaNomeacao()"
      class="ml-8"
      size="small"
      color="success"
    >
      <span class="font-semibold pr-2 pl-2">
        Enviar Carta de nomeação
      </span>
    </vs-button>
  </div>
</template>
<script>
import azViewUpload from "./AzViewUpload";
import { mapActions, mapGetters } from "vuex";
import instance from "@/axios";
const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = error => reject(error);
  });
};
let lastTap = 0;
let $azu = undefined;
export default {
  name: "carta-nomeacao",
  components: {
    azViewUpload
  },
  props: {
    tomador: Object,
    nomeationRequestCallback: Function,

    documentoName: {
      default: null,
      type: String
    },
    uniqueId: {
      default: null,
      type: String
    },

    modalUpload: {
      default: null,
      type: Boolean
    },
    text: {
      default: "Clique aqui para selecionar o arquivo.",
      type: String
    },
    textMax: {
      default: "Maximum of files reached",
      type: String
    },
    limit: {
      default: null,
      type: [Number, String]
    },
    action: {
      default: null,
      type: String
    },
    headers: {
      default: null,
      type: Object
    },
    data: {
      default: null,
      type: Object
    },
    automatic: {
      default: false,
      type: Boolean
    },
    showUploadButton: {
      default: true,
      type: Boolean
    },
    singleUpload: {
      default: true,
      type: Boolean
    },
    insertName: {
      default: false,
      type: Boolean
    },
    controller: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      uploadCartaNomeacaoController: {},
      viewActive: false,
      viewSrc: null,
      srcs: [],
      filesx: [],
      files: [],
      itemRemove: [],
      azObject: {},
      percent: 0,
      base64: false,
      Filename: false,
      FileExtension: false
    };
  },
  methods: {
    ...mapActions("tomador-module", ["solicitarNomeacaoTomador"]),
    veriryFile(name) {
      let toLowerCase = name.toLowerCase();
      let extension = toLowerCase.split(".").pop();
      console.log("arquivo: ", extension);
      let types = [
        "png",
        "jpeg",
        "jpg",
        "xlsx",
        "xls",
        "rtf",
        "docx",
        "pptx",
        "odt",
        "pdf"
      ];
      let result = null;
      return types.includes(extension);
    },

    viewImage(src, evt) {
      let timeout;
      const eventx =
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch)
          ? "touchstart"
          : "click";
      if (eventx === "click") {
        this.viewActive = true;
        this.viewSrc = src;
      } else if (evt.type === "touchend") {
        const currentTime = new Date().getTime();
        const tapLength = currentTime - lastTap;
        clearTimeout(timeout);
        if (tapLength < 500 && tapLength > 0) {
          this.viewActive = true;
          this.viewSrc = src;
          event.preventDefault();
        }
        lastTap = currentTime;
      }
    },
    removeFile(fileToRemove) {
      $azu.removeFile(fileToRemove);
      this.base64 = false;
      this.files = $azu.files;
    },
    getFiles(e) {
      let fileSize = e.target.files[0].size;
      let fileMb = fileSize / 1024 ** 2;
      if (fileMb > 25) {
        this.$onpoint.errorModal(
          "Arquivo muito grande, escolha arquivos até 25mb e tente novamente!"
        );
      } else {
        if (this.veriryFile(e.target.files[0].name)) {
          getBase64(e.target.files[0]).then(result => {
            this.base64 = result;
          });
          this.Filename = e.target.files[0].name.split(".")[0];
          this.FileExtension = e.target.files[0].name.split(".").pop();
          const _this = this;
          const files = e.target.files;
          const count = this.srcs.length - this.itemRemove.length;
          //Adiciona todos os arquivos no componente;
          $azu.addFiles(e.target.files, e);
          this.files = $azu.files;
        } else {
          this.$onpoint.errorModal("Arquivo inválido, tente novamente!");
        }
      }
    },
   async enviarCartaNomeacao() {
      const tomadorSelecionado = this.tomador;
      if (!tomadorSelecionado || tomadorSelecionado.Id == 0) {
        this.$onpoint.errorModal("Informe o tomador no componente.");
        return;
      }
      const payload = {
        FileExtension: this.FileExtension,
        Filename: this.Filename,
        Base64Data: this.base64
      };
      await this.$onpoint.loadingModal(
        "Estamos enviando sua carta de nomeação para análise.",
       async () => {
          return await instance({
            method: "post",
            url: "/api/Archive/UploadBlob",
            data: payload
          })
            .then(async callback => {
              console.log(callback.data)
              const request = {
                PolicyHolderId: tomadorSelecionado.Id,
                NomeationTypeId: 3, //Carta de nomeação
                Files: [
                  {
                    name: callback.data.Response.Filename,
                    uniqueIdentifier: callback.data.Response.UniqueId,
                    extension: callback.data.Response.Extension,
                    relativePath: callback.data.Response.Filename
                  }
                ]
              };
              return await this.solicitarNomeacaoTomador(request).then(() => {
                this.$onpoint.successModal(
                  "Carta de nomeação enviada com sucesso. Aguarde a análise da seguradora."
                );
                this.nomeationRequestCallback({
                  ...tomadorSelecionado,
                  HasPendingNomeationProcess: true,
                  LastPolicyHolderNomeationRequest: {
                    RequestDate: this.$moment()
                  }
                });
              });
            })
            .catch(ex => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            });
        }
      );
    }
  },
  computed: {
    ...mapGetters("auth", ["storageInfo"])
  },
  watch: {
    percent() {
      if (this.percent >= 100) {
        this.srcs.forEach(file => {
          file.percent = 100;
        });
        setTimeout(() => {
          this.percent = 0;
        }, 1000);
      }
    }
  },
  mounted() {
    this.azObject = this.storageInfo;

    //Criando o controlador do upload
    if (this.controller) {
      this.controller.sas = this.azObject;
      this.controller.files = function() {
        return $azu.files || [];
      };

      this.controller.reset = function() {
        return $azu.reset();
      };

      this.controller.remove = function() {
        return $azu.remove();
      };

      this.controller.upload = function() {
        return new Promise(function(resolve, reject) {
          if (($azu.files || {}).length === 0) return resolve(undefined);

          $azu.off("complete");
          $azu.off("progress");
          $azu.off("error");
          $azu.off("fileSuccess");

          $azu.on("fileSuccess", function($file, $message, $chunk) {});

          $azu.on("complete", function() {
            //deferred.resolve();
            const files = [];
            $azu.files.forEach(function(file) {
              files.push({
                name: file.name,
                uniqueIdentifier: file.uniqueIdentifier,
                extension: file.extension,
                relativePath: file.relativePath
              });
            });

            return resolve(files);
          });

          $azu.on("progress", function() {});

          $azu.on("error", function($message, $file, $chunk) {
            return reject({
              message: $message,
              file: $file,
              chunk: $chunk
            });
          });

          $azu.startUpload();
        });
      };
    }
    //FAZER UMA REQUEST AQUI PARA BUSCAR ESSAS CHAVES DO BACK.
    if (this.azObject.Uri) {
      $azu = AzUpload.newInstance({
        azUri: this.azObject.Uri,
        azContainer: this.azObject.ContainerName,
        azSignature: this.azObject.Signature
      });
      const self = this;
      const catchAllHandler = function(eventName) {
        const args = Array.prototype.slice.call(arguments);
        args.shift();

        //self.$emi($scope, ['flow::' + eventName, $scope.$azu].concat(args));
        /**if ({
                'progress': 1, 'filesSubmitted': 1, 'fileSuccess': 1, 'fileError': 1, 'complete': 1, 'reseted': 1
              }[eventName]) {
                  $scope.safeApply();
              }
              if (event.defaultPrevented) {
                  return false;
              }**/
      };
      $azu.on("catchAll", catchAllHandler);
      $azu.on("fileSuccess", function($file, $message, $chunk) {});
      $azu.on("complete", function() {
        const files = [];
        $azu.files.forEach(function(file) {
          files.push({
            name: file.name,
            uniqueIdentifier: file.uniqueIdentifier,
            extension: file.extension,
            relativePath: file.relativePath
          });
        });
        //Isso deve ser enviado para o serviço. São as informações necessárias para salvarmos no back;
      });
      $azu.on("progress", function() {});

      $azu.on("error", function($message, $file, $chunk) {});
    }
  }
};
</script>

<style lang="scss" scoped>
.con-img-upload {
  background: transparent;
  display: flex;
  align-content: center;
  justify-content: flex-start;
}
</style>
