var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "required-documents" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup-list-documents",
          attrs: {
            title: "Solicitação de Revisão de limite",
            active: _vm.showModalDocuments,
          },
          on: {
            "update:active": function ($event) {
              _vm.showModalDocuments = $event
            },
          },
        },
        [
          _vm.success
            ? _c("el-alert", {
                staticClass: "mb-8 px-8",
                attrs: {
                  title: "Documento enviados com sucesso!",
                  type: "success",
                  description:
                    "Sua solicitação foi enviada para análise da seguradora!",
                  "show-icon": "",
                },
              })
            : _c(
                "div",
                { staticClass: "mb-8 px-8" },
                [
                  _c("p", { staticClass: "mb-5" }, [
                    _vm._v("Documentos marcados com * são obrigatórios"),
                  ]),
                  _c(
                    "vs-list",
                    _vm._l(_vm.detalhamento, function (doc, index) {
                      return _c(
                        "vs-list-item",
                        {
                          key: index,
                          class: doc.StatusName,
                          attrs: {
                            title: doc.Name + (doc.IsRequired ? " *" : ""),
                            subtitle: doc.StatusDescription,
                          },
                        },
                        [
                          doc.StatusName !== "Conforme"
                            ? _c("vs-button", {
                                attrs: {
                                  size: "large",
                                  type: "flat",
                                  icon: "cloud_upload",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.modalUploadOpen(doc, index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("p", { staticClass: "mb-2" }, [_vm._v("Comentário")]),
                      _c("vs-textarea", {
                        model: {
                          value: _vm.observation,
                          callback: function ($$v) {
                            _vm.observation = $$v
                          },
                          expression: "observation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right mt-5 w-full" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.validaDocumentosObrigatorios,
                            type: "filled",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.finalizarPendencia()
                            },
                          },
                        },
                        [_vm._v("Submeter para seguradora")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm.modalUpload
        ? _c("multiUpload", {
            attrs: {
              modalUpload: _vm.modalUpload,
              documentoName: (_vm.selectedDocument || {}).Name,
            },
            on: {
              callBackUpload: _vm.callBackUpload,
              closeModal: _vm.closeModal,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }